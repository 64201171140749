
import { useState, useEffect } from "react";

const DEFAULT_TITLE = "Hello there";

export default function useTitle() {
    const [title, setTitle] = useState("");

    useEffect(() => {
        document.title = `${title || DEFAULT_TITLE} | Mymeo`;

        return () => {
            document.title = `${DEFAULT_TITLE} | Mymeo`;
        }
    }, [title])

    return {
        title,
        setTitle
    }
}
import {
  Cross2Icon,
  BellIcon,
  PlusIcon,
  TrashIcon,
} from "@radix-ui/react-icons";
import {
  Button,
  Flex,
  IconButton,
  Kbd,
  TextField,
  Text,
  Avatar,
} from "@radix-ui/themes";
import { password } from "telegram";
import { auth } from "../firebase";
import {
  redirect,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import * as Dialog from "@radix-ui/react-dialog";
import * as Switch from "@radix-ui/react-switch";

import calendar from "../assets/calendar-logo.svg";
import chromeLogo from "../assets/chrome-logo.svg";
import gmailLogo from "../assets/gmail-logo.svg";
import telegramLogo from "../assets/tg-logo.svg";
import notionLogo from "../assets/notion-logo.svg";
import { useEffect, useState } from "react";
import {
  useCallbackCreateMutation,
  useContactsListQuery,
  useEventsCreateMutation,
  useSigninListQuery,
} from "../API/ReDay/reday";
import useExtension from "../hooks/useExtension";
import useAnalytics from "../hooks/useAnalytics";
import useTitle from "../hooks/useTitle";

export function Settings() {
  const [
    sendTest,
    auth,
    contactsList,
    me,
    projects,
    setActiveProject,
    activeProject,
    password,
    phoneCode,
    phoneNumber,
    inputChangeHandler,
    sendCodeHandler,
    clientStartHandler,
    loginFB,
    fb,
    user,
    gmail,
    loginGoogle,
    gcontactsList,
  ] = useOutletContext<any>();

  const navigate = useNavigate();
  const { isExtension, extensionInstalled, setExtensionInstalled } =
    useExtension();

  const [state, setState] = useState(1);
  const { setTitle } = useTitle()

  const toggles = JSON.parse(localStorage.getItem("toggles") || "[]");
  const confirmToggleValue = JSON.parse(
    localStorage.getItem("confirmToggle") || "true"
  );

  const [featureToggle, setFeatureToggle] = useState(toggles);
  const [confirmToggle, setConfirmToggle] = useState(confirmToggleValue);
  const [code, setCode] = useState<string | null>(null);

  const [callback, callbackState] = useCallbackCreateMutation();
  const [getEvents, eventsState] = useEventsCreateMutation();
  // const [getContacts, contactsState] = useContactsCreateMutation()
  // const { data: contacts, refetch } = useContactsListQuery({
  //   authorization: user?.token,
  //   email: user?.email,
  // });

  const { data, isLoading } = useSigninListQuery({
    url: window.location.origin + "/settings/",
  });
  console.log(data, isLoading);
  const analytics = useAnalytics();

  

  useEffect(() => {
    if (code) {
      handleGetToket();
    } else return;
  }, [code]);

  useEffect(() => {
    setTitle("Settings")
    // debugger;
    const currentURL = window.location.href;
    const urlParams = new URLSearchParams(currentURL);
    const code = urlParams.get("code");
    code?.length && setCode(code);
  }, []);

  const handleGetToket = async () => {
    try {
      const res: any = await callback({
        body: {
          code: code || "",
          url: window.location.origin + "/settings/",
        },
      });

      if ((res as any).data.user_info && (res as any).data.token) {
        // debugger
        
        localStorage.setItem("Reday", JSON.stringify(res.data));
        analytics.connectCalendar();
        
        if (window.chrome) {
          if (window.chrome.runtime) {
            window.chrome.runtime.sendMessage(
              localStorage.getItem("extension"),
              { message: "loginInCalendar", user: res.data },
              function (a: any) {
                console.log("message sent 1", a);
              }
            );
          }
        }

        navigate("/settings");
        loginGoogle("auto");
        // setIsLoading(false);
      }
    } catch (error) {}
  };

  const gredirect = () => {
    if (isExtension) {
      window.location.href =
        "https://app.mymeo.ai/settings/?action=connectGmail";
      return;
    }

    if (!gmail) {
      var url = new URL((data as any).auth_link);
      url.searchParams.set(
        "redirect_uri",
        window.location.origin + "/settings/"
      );

      console.log(url.href);
      window.location.href = url.href;
    }
  };

  if (!isLoading && data) {
    const action = new URLSearchParams(window.location.search).get("action");
    if (action === "connectGmail") {
      if (!gmail) {
        gredirect()
      } else {
        const r = JSON.parse(localStorage.getItem("Reday") || "{}");
        if (window.chrome) {
          if (window.chrome.runtime) {
            window.chrome.runtime.sendMessage(
              localStorage.getItem("extension"),
              { message: "loginInCalendar", user: r },
              function (a: any) {
                console.log("message sent 1", a);
              }
            );
          }
        }
      }
    }
  }

  useEffect(() => {
    localStorage.setItem("toggles", JSON.stringify(featureToggle));
  }, [featureToggle]);

  useEffect(() => {
    localStorage.setItem("confirmToggle", JSON.stringify(confirmToggle));
  }, [confirmToggle]);

  function logout() {
    localStorage.removeItem("SB");

    if (isExtension) {
      window.location.href = window.location.origin + "/index.html";
    } else {
      window.location.reload();
    }
  }

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        padding: "80px",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "30px",
          right: "30px",
          cursor: "pointer",
        }}
        onClick={() => navigate("/")}
      >
        <Cross2Icon height={24} width={24}></Cross2Icon>
      </div>
      <div
        style={{
          width: "100%",
          maxWidth: "1024px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "start",
          background: "#fff",
          columnGap: "30px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            rowGap: "10px",
            flex: 1,
            fontFamily: "Ubuntu",
            textTransform: "capitalize",
            fontSize: "14px",
            lineHeight: "1.2",
          }}
        >
          <div
            onClick={() => setState(0)}
            style={{
              color: state === 0 ? "#fff" : "#000",
              background: state === 0 ? "rgba(150, 192, 255, 1)" : "#fff",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "1.2",
              padding: "8px",
              borderRadius: "10px",
              cursor: "pointer",
            }}
          >
            profile
          </div>
          <div
            onClick={() => setState(1)}
            style={{
              color: state === 1 ? "#fff" : "#000",
              background: state === 1 ? "rgba(150, 192, 255, 1)" : "#fff",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "1.2",
              padding: "8px",
              borderRadius: "10px",
              cursor: "pointer",
            }}
          >
            Integration
          </div>
          <div
            onClick={() => setState(2)}
            style={{
              color: state === 2 ? "#fff" : "#000",
              background: state === 2 ? "rgba(150, 192, 255, 1)" : "#fff",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "1.2",
              padding: "8px",
              borderRadius: "10px",
              cursor: "pointer",
            }}
          >
            Reminders
          </div>
        </div>

        {state === 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "10px",
              flex: 4,
            }}
          >
            <div
              style={{
                fontFamily: "Ubuntu",
                color: "#000",
                fontSize: "32px",
                lineHeight: "1.2",
              }}
            >
              Profile
            </div>
            <div
              style={{
                color: "rgba(152, 161, 176, 1)",
                fontFamily: "Ubuntu",
                fontSize: "14px",
                lineHeight: "1.4",
              }}
            >
              Update your photo and details here.
            </div>

            <div
              style={{
                marginTop: "20px",
                display: "flex",
                flexDirection: "column",
                rowGap: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "10px",
                }}
              >
                <div
                  style={{
                    fontFamily: "Ubuntu",
                    color: "#000",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                >
                  Email
                </div>
                <input
                  style={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "12px",
                    border: "1px solid rgba(194, 199, 208, 1)",
                    padding: "0 16px",
                    boxSizing: "border-box",
                  }}
                  className="input-focus"
                  type="text"
                  name=""
                  id=""
                  disabled
                  value={user?.email}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "10px",
                }}
              >
                <div
                  style={{
                    fontFamily: "Ubuntu",
                    color: "#000",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                >
                  Name
                </div>
                <input
                  style={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "12px",
                    border: "1px solid rgba(194, 199, 208, 1)",
                    padding: "0 16px",
                    boxSizing: "border-box",
                  }}
                  disabled
                  className="input-focus"
                  type="text"
                  name=""
                  id=""
                  value={user?.displayName}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "10px",
                }}
              >
                <div
                  style={{
                    fontFamily: "Ubuntu",
                    color: "#000",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                >
                  Language
                </div>
                <input
                  style={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "12px",
                    border: "1px solid rgba(194, 199, 208, 1)",
                    padding: "0 16px",
                    boxSizing: "border-box",
                  }}
                  className="input-focus"
                  type="text"
                  name=""
                  id=""
                />
              </div>
            </div>

            <div
              style={{
                margin: "30px 0",
                width: "100%",
                height: "1px",
                background: "rgba(235, 237, 240, 1)",
              }}
            ></div>

            <div
              style={{
                background: "rgba(235, 237, 240, 1)",
                padding: "16px",
                borderRadius: "12px",
                fontSize: "14px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div>
                Request confirmation for each action on connected accounts
              </div>
              <Switch.Root
                className="SwitchRoot"
                // id="airplane-mode"
                onClick={() => {
                  setConfirmToggle(!confirmToggle);
                }}
                checked={confirmToggle}
              >
                <Switch.Thumb className="SwitchThumb" />
              </Switch.Root>
            </div>

            <div
              style={{
                margin: "30px 0",
                width: "100%",
                height: "1px",
                background: "rgba(235, 237, 240, 1)",
              }}
            ></div>

            <div
              style={{
                cursor: "pointer",
                color: "rgba(0, 101, 255, 1)",
                fontFamily: "Ubuntu",
                fontSize: "16px",
                fontWeight: 500,
              }}
              onClick={() => logout()}
            >
              Log out
            </div>
            <div
              style={{
                cursor: "pointer",
                color: "rgba(235, 87, 87, 1)",
                fontFamily: "Ubuntu",
                fontSize: "16px",
                fontWeight: 500,
              }}
            >
              Delete account
            </div>
          </div>
        )}

        {state === 1 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "10px",
              flex: 4,
            }}
          >
            <div
              style={{
                fontFamily: "Ubuntu",
                color: "#000",
                fontSize: "32px",
                lineHeight: "1.2",
              }}
            >
              Integration
            </div>
            <div
              style={{
                color: "rgba(152, 161, 176, 1)",
                fontFamily: "Ubuntu",
                fontSize: "14px",
                lineHeight: "1.4",
              }}
            >
              Dive in and let Mymeo be your guide to the future.
            </div>
            {gmail && (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "10px",
                }}
              >
                <div
                  style={{
                    fontFamily: "Ubuntu",
                    color: "#000",
                    fontWeight: "500",
                    fontSize: "16px",
                  }}
                >
                  Connected
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: "16px",
                    borderRadius: "10px",
                    border: "1px solid rgba(235, 237, 240, 1)",
                    background: "rgba(250, 251, 251, 1)",
                    cursor: "pointer",
                  }}
                  onClick={() => loginGoogle()}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "16px",
                    }}
                  >
                    <div style={{ display: "flex", position: "relative" }}>
                      <Avatar
                        radius="full"
                        src={gmail?.user_info.picture}
                        fallback={gmail?.user_info.email[0]}
                        style={{ cursor: "pointer" }}
                      />
                      {/* <img
                          src={gmail?.image}
                          height={40}
                          style={{ borderRadius: "100px" }}
                          alt="avatar"
                        /> */}
                      <img
                        src={calendar}
                        height={24}
                        alt="system"
                        style={{
                          position: "absolute",
                          bottom: "-5px",
                          right: "-5px",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          fontFamily: "Ubuntu",
                          color: "#000",
                          fontWeight: "500",
                          fontSize: "16px",
                          alignItems: "stretch",
                        }}
                      >
                        {gmail?.user_info.email}
                      </div>
                      <div
                        style={{
                          color: "rgba(152, 161, 176, 1)",
                          fontFamily: "Ubuntu",
                          fontSize: "14px",
                          lineHeight: "1",
                        }}
                      >
                        Events, contacts
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: "10px",
                    }}
                  >
                    <TrashIcon height={20} width={20} />
                  </div>
                </div>
              </div>
            )}

            <div
              style={{
                marginTop: "20px",
                display: "flex",
                flexDirection: "column",
                rowGap: "10px",
              }}
            >
              <div
                style={{
                  fontFamily: "Ubuntu",
                  color: "#000",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                Add
              </div>
              {!gmail && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: "16px",
                    borderRadius: "10px",
                    border: "1px solid rgba(235, 237, 240, 1)",
                    background: "rgba(250, 251, 251, 1)",
                    cursor: "pointer",
                  }}
                  onClick={() => gredirect()}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "16px",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <img src={calendar} />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          fontFamily: "Ubuntu",
                          color: "#000",
                          fontWeight: "500",
                          fontSize: "16px",
                          alignItems: "stretch",
                        }}
                      >
                        Google Calendar
                      </div>
                      <div
                        style={{
                          color: "rgba(152, 161, 176, 1)",
                          fontFamily: "Ubuntu",
                          fontSize: "14px",
                          lineHeight: "1",
                        }}
                      >
                        I can help you with managing your working rutinue
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: "10px",
                    }}
                  >
                    <PlusIcon height={20} width={20} />
                  </div>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: "16px",
                  borderRadius: "10px",
                  border: "1px solid rgba(235, 237, 240, 1)",
                  background: "rgba(250, 251, 251, 1)",
                  cursor: "pointer",
                }}
                onClick={() => {}}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    columnGap: "16px",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <img src={gmailLogo} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Ubuntu",
                        color: "#000",
                        fontWeight: "500",
                        fontSize: "16px",
                        alignItems: "stretch",
                      }}
                    >
                      Gmail
                    </div>
                    <div
                      style={{
                        color: "rgba(152, 161, 176, 1)",
                        fontFamily: "Ubuntu",
                        fontSize: "14px",
                        lineHeight: "1",
                      }}
                    >
                      Import all your people network base and events from your
                      calendars.
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "10px",
                    color: "rgba(152, 161, 176, 1)",
                    fontFamily: "Ubuntu",
                  }}
                >
                  Soon
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: "16px",
                  borderRadius: "10px",
                  border: "1px solid rgba(235, 237, 240, 1)",
                  background: "rgba(250, 251, 251, 1)",
                  cursor: "pointer",
                }}
                onClick={() => {}}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    columnGap: "16px",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <img src={telegramLogo} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Ubuntu",
                        color: "#000",
                        fontWeight: "500",
                        fontSize: "16px",
                        alignItems: "stretch",
                      }}
                    >
                      Telegram
                    </div>
                    <div
                      style={{
                        color: "rgba(152, 161, 176, 1)",
                        fontFamily: "Ubuntu",
                        fontSize: "14px",
                        lineHeight: "1",
                      }}
                    >
                      Import all your people network base and events from your
                      calendars.
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "10px",
                    color: "rgba(152, 161, 176, 1)",
                    fontFamily: "Ubuntu",
                  }}
                >
                  Soon
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: "16px",
                  borderRadius: "10px",
                  border: "1px solid rgba(235, 237, 240, 1)",
                  background: "rgba(250, 251, 251, 1)",
                  cursor: "pointer",
                }}
                onClick={() => {}}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    columnGap: "16px",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <img src={chromeLogo} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Ubuntu",
                        color: "#000",
                        fontWeight: "500",
                        fontSize: "16px",
                        alignItems: "stretch",
                      }}
                    >
                      Browser activity
                    </div>
                    <div
                      style={{
                        color: "rgba(152, 161, 176, 1)",
                        fontFamily: "Ubuntu",
                        fontSize: "14px",
                        lineHeight: "1",
                      }}
                    >
                      Import all your people network base and events from your
                      calendars.
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "10px",
                    color: "rgba(152, 161, 176, 1)",
                    fontFamily: "Ubuntu",
                  }}
                >
                  Soon
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: "16px",
                  borderRadius: "10px",
                  border: "1px solid rgba(235, 237, 240, 1)",
                  background: "rgba(250, 251, 251, 1)",
                  cursor: "pointer",
                }}
                onClick={() => {}}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    columnGap: "16px",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <img src={notionLogo} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Ubuntu",
                        color: "#000",
                        fontWeight: "500",
                        fontSize: "16px",
                        alignItems: "stretch",
                      }}
                    >
                      Notion
                    </div>
                    <div
                      style={{
                        color: "rgba(152, 161, 176, 1)",
                        fontFamily: "Ubuntu",
                        fontSize: "14px",
                        lineHeight: "1",
                      }}
                    >
                      Import all your people network base and events from your
                      calendars.
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "10px",
                    color: "rgba(152, 161, 176, 1)",
                    fontFamily: "Ubuntu",
                  }}
                >
                  Soon
                </div>
              </div>
            </div>
          </div>
        )}

        {state === 2 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "10px",
              flex: 4,
            }}
          >
            <div
              style={{
                fontFamily: "Ubuntu",
                color: "#000",
                fontSize: "32px",
                lineHeight: "1.2",
              }}
            >
              Reminders
            </div>
            <div
              style={{
                color: "rgba(152, 161, 176, 1)",
                fontFamily: "Ubuntu",
                fontSize: "14px",
                lineHeight: "1.4",
              }}
            >
              Dive in and let Mymeo be your guide to the future.
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "20px",
                marginTop: "20px",
              }}
            >
              <div
                style={{
                  fontFamily: "Ubuntu",
                  color: "#000",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "1.2",
                }}
              >
                Event reminders
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  columnGap: "20px",
                  alignItems: "center",
                }}
              >
                <input
                  style={{
                    height: "40px",
                    borderRadius: "12px",
                    border: "1px solid rgba(194, 199, 208, 1)",
                    padding: "0 16px",
                    boxSizing: "border-box",
                    flex: 9,
                  }}
                  disabled
                  type="text"
                  className="input-focus"
                  name=""
                  id=""
                  value="Foresseable future events (1 hour)"
                />

                <div
                  style={{
                    display: "flex",
                    flex: 1,
                  }}
                >
                  <Switch.Root
                    className="SwitchRoot"
                    // id="airplane-mode"
                    onClick={() => {
                      setFeatureToggle(
                        featureToggle.map((f: any) => {
                          if (f.name === "eventsInAnHour") {
                            return { ...f, value: !f.value };
                          }
                          return f;
                        })
                      );
                    }}
                    checked={
                      featureToggle.find(
                        (f: any) => f.name === "eventsInAnHour"
                      )?.value
                    }
                  >
                    <Switch.Thumb className="SwitchThumb" />
                  </Switch.Root>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  columnGap: "20px",
                  alignItems: "center",
                }}
              >
                <input
                  style={{
                    height: "40px",
                    borderRadius: "12px",
                    border: "1px solid rgba(194, 199, 208, 1)",
                    padding: "0 16px",
                    boxSizing: "border-box",
                    flex: 9,
                  }}
                  disabled
                  type="text"
                  className="input-focus"
                  name=""
                  id=""
                  value="Meetings in progress"
                />

                <div
                  style={{
                    display: "flex",
                    flex: 1,
                  }}
                >
                  <Switch.Root
                    className="SwitchRoot"
                    id="airplane-mode"
                    onClick={() => {
                      setFeatureToggle(
                        featureToggle.map((f: any) => {
                          if (f.name === "meetingsInProgress") {
                            return { ...f, value: !f.value };
                          }
                          return f;
                        })
                      );
                    }}
                    checked={
                      featureToggle.find(
                        (f: any) => f.name === "meetingsInProgress"
                      )?.value
                    }
                  >
                    <Switch.Thumb className="SwitchThumb" />
                  </Switch.Root>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  columnGap: "20px",
                  alignItems: "center",
                }}
              >
                <input
                  style={{
                    height: "40px",
                    borderRadius: "12px",
                    border: "1px solid rgba(194, 199, 208, 1)",
                    padding: "0 16px",
                    boxSizing: "border-box",
                    flex: 9,
                  }}
                  disabled
                  type="text"
                  className="input-focus"
                  name=""
                  id=""
                  value="Meetings overlaps"
                />

                <div
                  style={{
                    display: "flex",
                    flex: 1,
                  }}
                >
                  <Switch.Root
                    className="SwitchRoot"
                    id="airplane-mode"
                    onClick={() => {
                      setFeatureToggle(
                        featureToggle.map((f: any) => {
                          if (f.name === "meetingsOverlap") {
                            return { ...f, value: !f.value };
                          }
                          return f;
                        })
                      );
                    }}
                    checked={
                      featureToggle.find(
                        (f: any) => f.name === "meetingsOverlap"
                      )?.value
                    }
                  >
                    <Switch.Thumb className="SwitchThumb" />
                  </Switch.Root>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  columnGap: "20px",
                  alignItems: "center",
                }}
              >
                <input
                  style={{
                    height: "40px",
                    borderRadius: "12px",
                    border: "1px solid rgba(194, 199, 208, 1)",
                    padding: "0 16px",
                    boxSizing: "border-box",
                    flex: 9,
                  }}
                  disabled
                  type="text"
                  className="input-focus"
                  name=""
                  id=""
                  value="Future events (today)"
                />

                <div
                  style={{
                    display: "flex",
                    flex: 1,
                  }}
                >
                  <Switch.Root
                    className="SwitchRoot"
                    id="airplane-mode"
                    onClick={() => {
                      setFeatureToggle(
                        featureToggle.map((f: any) => {
                          if (f.name === "todaysFutureEvents") {
                            return { ...f, value: !f.value };
                          }
                          return f;
                        })
                      );
                    }}
                    checked={
                      featureToggle.find(
                        (f: any) => f.name === "todaysFutureEvents"
                      )?.value
                    }
                  >
                    <Switch.Thumb className="SwitchThumb" />
                  </Switch.Root>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  columnGap: "20px",
                  alignItems: "center",
                }}
              >
                <input
                  style={{
                    height: "40px",
                    borderRadius: "12px",
                    border: "1px solid rgba(194, 199, 208, 1)",
                    padding: "0 16px",
                    boxSizing: "border-box",
                    flex: 9,
                  }}
                  disabled
                  type="text"
                  className="input-focus"
                  name=""
                  id=""
                  value="Passed events (summary for the day)"
                />

                <div
                  style={{
                    display: "flex",
                    flex: 1,
                  }}
                >
                  <Switch.Root
                    className="SwitchRoot"
                    id="airplane-mode"
                    onClick={() => {
                      setFeatureToggle(
                        featureToggle.map((f: any) => {
                          if (f.name === "todaysPassedEvents") {
                            return { ...f, value: !f.value };
                          }
                          return f;
                        })
                      );
                    }}
                    checked={
                      featureToggle.find(
                        (f: any) => f.name === "todaysPassedEvents"
                      )?.value
                    }
                  >
                    <Switch.Thumb className="SwitchThumb" />
                  </Switch.Root>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

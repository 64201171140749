
import { uuid } from "short-uuid";
import { db } from "../firebase";
import { set, ref } from "firebase/database";
import useExtension from "./useExtension";
// import { current } from "@reduxjs/toolkit";
const session = uuid();
const current = Date.now()

const GA_ENDPOINT = 'https://www.google-analytics.com/mp/collect';
const MEASUREMENT_ID = `G-VDCQH3194K`;
const API_SECRET = `nhPaN53uSG28oldR5BuQWA`;



export default function useAnalytics() { 
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    

    const getUser = () => { 
        const user = JSON.parse(localStorage.getItem("SB") || "{}");
        return {
            email: user.email || "",
            name: user.displayName || "",
            uid: user.uid || "",
        };
    }

    const getConnections = () => { 
        const user = JSON.parse(localStorage.getItem("Reday") || "{}");
        return {
            email: user.user_info?.email || "",
            id: user.user_info?.id || "",
        };
    }

    const { isExtension } = useExtension();

    const ga = (userId: string,eventName: string, event: any) => { 
        fetch(
            `${GA_ENDPOINT}?measurement_id=${MEASUREMENT_ID}&api_secret=${API_SECRET}`,
            {
              method: 'POST',
              body: JSON.stringify({
                client_id: userId,
                  events: [{
                      name: eventName,
                      params: {
                        session_id: current,
                        engagement_time_msec: 100,
                          ...event,
                          
                      }
                  }
                ],
              }),
            }
          );
    }
    
    const trackEvent = (category: string, action: string, label: string, value: string) => {
        const timeStamp = new Date().getTime();
        const params = {
            user: getUser(),
            gmail: getConnections(),
            category,
            action,
            label,
            value,
            isExtension,
            session,
            timeStamp
        }
        setTimeout(() => {
            set(ref(db, `events/${formattedDate}/${timeStamp}`), params )
        }, 0);
        ga(params.user.uid, action, params)
    }

    const trackPageView = (page: string) => {
        const timeStamp = new Date().getTime();
        const params = {
            user: getUser(),
            gmail: getConnections(),
            page,
            isExtension,
            session,
            timeStamp,
            page_title: document.title,
            page_location: document.location.href
        }
        setTimeout(() => {
            set(ref(db, `pageviews/${formattedDate}/${timeStamp}`), params)
        }, 0);
        ga(params.user.uid, "pageviews" , params)
    }

    return {
        connectCalendar: () => {
            trackEvent("Calendar", "Connect", "Connect Calendar", "1");
        },
        disconnectCalendar: () => {
            const user = JSON.parse(localStorage.getItem("Reday") || "{}")
            trackEvent("Calendar", "Disconnect", "Disconnect Calendar", user.user_info?.email || "");
        },
        unintendedDisconnect: (reason: string) => { 
            trackEvent("Calendar", "Disconnect", "Unintended Disconnect", reason);
        },
        assistancePrompt: (propt: string) => { 
            trackEvent("Assistan", "Prompt", "Assistance Prompt", propt);
        },
        acceptedAction: (action: string) => { 
            trackEvent("Assistan", "Accepted", "Accepted Action", action);
        },
        rejectedAction: (action: string) => { 
            trackEvent("Assistan", "Rejected", "Rejected Action", action);
        },
        visitApp: () => { 
            isExtension ? trackPageView("Extension") :
            trackPageView("Web App");
        }
    }
}
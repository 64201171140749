import { useState } from "react";

export default function useExtension() {
    const isExtension = window.location.protocol === "chrome-extension:";
    const [extensionInstalled, setExtensionInstalled] = useState(localStorage.getItem("extensionInstalled"));

    return {
        isExtension,
        extensionInstalled,
        setExtensionInstalled
    }
}
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

const firebaseConfig = {
    apiKey: "AIzaSyCePA-OTRZaaWsXP4nrk4IpePGczifAdwU",
    authDomain: "sb-poc-ecc43.firebaseapp.com",
    // databaseURL: "https://sb-poc-ecc43-default-rtdb.firebaseio.com",
    projectId: "sb-poc-ecc43",
    storageBucket: "sb-poc-ecc43.appspot.com",
    messagingSenderId: "131696973166",
    appId: "1:131696973166:web:0741c985dbbae2e7af1d7f",
    measurementId: "G-9EXF496RHH"
  };
 
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const db = getDatabase(app);
export default app;
import { Box, Grid, Card, Flex, Avatar, Text, Button } from "@radix-ui/themes";
import { useOutletContext } from "react-router-dom";

export function Contacts() {
  const [
    sendTest,
    auth,
    contactsList,
    me,
    projects,
    setActiveProject,
    activeProject,
    password,
    phoneCode,
    phoneNumber,
    inputChangeHandler,
    sendCodeHandler,
    clientStartHandler,
    loginFB,
    fb,
    user,
    gmail,
    loginGoogle,
    gcontactsList,
  ] = useOutletContext<any>();

  console.log(gcontactsList)
  return (
    <Flex
      direction={"column"}
      align={"start"}
      justify={"start"}
      gap={"2"}
      p={"3"}
    >
      {auth ? contactsList ? ( <>
      <Text size={"6"}>
        Contacts from telegram {me?.firstName} {me?.lastName}
      </Text>
      <Button onClick={() => sendTest()}> send test message </Button>
      <Grid columns={"4"} gap={"4"}>
        <Text> Telegram contact list</Text>
        {contactsList?.map((contact: any) => (
          <Card style={{ maxWidth: 220 }}>
            <Flex gap="3" align="center" height={"100%"}>
              <Avatar size="3" radius="full" fallback={contact.firstName[0]} />
              <Box>
                <Text>{/* {client.downloadMedia(contact.photo)} */}</Text>
                <Text as="div" size="2" weight="bold">
                  {contact.firstName} {contact.lastName}
                </Text>
                <Text as="div" size="2" color="gray">
                  {contact.phone}
                </Text>
                {contact.username ? (
                  <Text as="div" size="2" color="gray">
                    @{contact.username}
                  </Text>
                ) : null}
              </Box>
            </Flex>
          </Card>
        ))}
      </Grid>
      </>) : "no telegram contacts" : <p>telegram is not conntected</p>}
      {
        gcontactsList.length ? <>
          <Grid columns={"4"} gap={"4"}>
        <Text> Google contact list</Text>
        {gcontactsList?.map((contact: any) => (
          <Card style={{ maxWidth: 220 }}>
            <Flex gap="3" align="center" height={"100%"}>
              <Avatar size="3" radius="full" fallback={contact.names[0].displayName[0]} src={ contact.photos[0].url} />
              <Box>
                <Text>{/* {client.downloadMedia(contact.photo)} */}</Text>
                <Text as="div" size="2" weight="bold">
                  {contact.names[0].displayName}
                </Text>
              </Box>
            </Flex>
          </Card>
        ))}
      </Grid>
        </> : <p>google contacts is not conntected</p>
      }
      
    </Flex>
  );
}

import { baseSplitApi as api } from "./baseAPI";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getV1Docs: build.query<GetV1DocsApiResponse, GetV1DocsApiArg>({
      query: () => ({ url: `/v1/docs` }),
    }),
    getV1OpenapiJson: build.query<
      GetV1OpenapiJsonApiResponse,
      GetV1OpenapiJsonApiArg
    >({
      query: () => ({ url: `/v1/openapi.json` }),
    }),
    versionVersionGet: build.query<
      VersionVersionGetApiResponse,
      VersionVersionGetApiArg
    >({
      query: () => ({ url: `/version` }),
    }),
    versionGet: build.query<VersionGetApiResponse, VersionGetApiArg>({
      query: () => ({ url: `/` }),
    }),
    chatChatPost: build.mutation<ChatChatPostApiResponse, ChatChatPostApiArg>({
      query: (queryArg) => ({
        url: `/chat`,
        method: "POST",
        body: queryArg.chatRequest,
      }),
    }),
    putCalendarInfoCalendarAuthPut: build.mutation<
      PutCalendarInfoCalendarAuthPutApiResponse,
      PutCalendarInfoCalendarAuthPutApiArg
    >({
      query: (queryArg) => ({
        url: `/calendar/auth`,
        method: "PUT",
        body: queryArg.calendarAuthPostRequest,
      }),
    }),
    postCalendarInfoCalendarAuthPost: build.mutation<
      PostCalendarInfoCalendarAuthPostApiResponse,
      PostCalendarInfoCalendarAuthPostApiArg
    >({
      query: (queryArg) => ({
        url: `/calendar/auth`,
        method: "POST",
        body: queryArg.calendarAuthPostRequest,
      }),
    }),
    postCalendarEventCalendarEventPost: build.mutation<
      PostCalendarEventCalendarEventPostApiResponse,
      PostCalendarEventCalendarEventPostApiArg
    >({
      query: (queryArg) => ({
        url: `/calendar/event`,
        method: "POST",
        body: queryArg.calendarEventPostRequest,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as coreAPI };
export type GetV1DocsApiResponse = /** status 200 Successful Response */ any;
export type GetV1DocsApiArg = void;
export type GetV1OpenapiJsonApiResponse =
  /** status 200 Successful Response */ any;
export type GetV1OpenapiJsonApiArg = void;
export type VersionVersionGetApiResponse =
  /** status 200 Successful Response */ VersionResponse;
export type VersionVersionGetApiArg = void;
export type VersionGetApiResponse =
  /** status 200 Successful Response */ VersionResponse;
export type VersionGetApiArg = void;
export type ChatChatPostApiResponse =
  /** status 200 Successful Response */ ChatResponse;
export type ChatChatPostApiArg = {
  chatRequest: ChatRequest;
};
export type PutCalendarInfoCalendarAuthPutApiResponse =
  /** status 200 Successful Response */ any;
export type PutCalendarInfoCalendarAuthPutApiArg = {
  calendarAuthPostRequest: CalendarAuthPostRequest;
};
export type PostCalendarInfoCalendarAuthPostApiResponse =
  /** status 200 Successful Response */ any;
export type PostCalendarInfoCalendarAuthPostApiArg = {
  calendarAuthPostRequest: CalendarAuthPostRequest;
};
export type PostCalendarEventCalendarEventPostApiResponse =
  /** status 200 Successful Response */ any;
export type PostCalendarEventCalendarEventPostApiArg = {
  calendarEventPostRequest: CalendarEventPostRequest;
};
export type VersionResponse = {
  version?: string;
};
export type ChatResponse = {
  user_id: string;
  response: string;
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type ChatRequest = {
  user_id: string;
  message: string;
};
export type CalendarAuthPostRequest = {
  user_id: string;
  access_token: string;
  refresh_token: string;
};
export type CalendarEventPostRequest = {
  user_id: string;
  start_time: string;
  title: string;
  end_time?: string;
  description?: string;
  location?: string;
  attendees?: string[];
};
export const {
  useGetV1DocsQuery,
  useLazyGetV1DocsQuery,
  useGetV1OpenapiJsonQuery,
  useLazyGetV1OpenapiJsonQuery,
  useVersionVersionGetQuery,
  useLazyVersionVersionGetQuery,
  useVersionGetQuery,
  useLazyVersionGetQuery,
  useChatChatPostMutation,
  usePutCalendarInfoCalendarAuthPutMutation,
  usePostCalendarInfoCalendarAuthPostMutation,
  usePostCalendarEventCalendarEventPostMutation,
} = injectedRtkApi;

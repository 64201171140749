import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

// initialize an empty api service that we'll inject endpoints into later as needed
export const baseSplitApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://sb-api-q594.onrender.com/',
    prepareHeaders: (headers, { getState }) => {
      const state: any = getState() 
      const token = state.auth?.accessToken;

      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      return headers
    }
  }),
  endpoints: () => ({}),
})
import {
  MagnifyingGlassIcon,
  DotsHorizontalIcon,
  MagicWandIcon,
  CheckIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  Cross1Icon,
  Cross2Icon,
} from "@radix-ui/react-icons";
import * as Avatar from "@radix-ui/react-avatar";
import { MentionsInput, Mention } from "react-mentions";
import avatarPlaceholder from "../assets/avatar.png";
import {
  Box,
  Flex,
  TextField,
  Text,
  IconButton,
  Badge,
  Grid,
  TextArea,
  Button,
} from "@radix-ui/themes";
import * as Checkbox from "@radix-ui/react-checkbox";
import * as RadioGroup from "@radix-ui/react-radio-group";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import OpenAI from "openai";
import { Bars, Triangle, LineWave, ThreeDots } from "react-loader-spinner";
import { useNavigate, useOutletContext } from "react-router-dom";
import { AutoTextSize } from "auto-text-size";
import { TypeAnimation } from "react-type-animation";
import { generate } from "random-words";
import { ChatCompletionMessageParam } from "openai/resources";
import { useKeyPressEvent } from "react-use";
import { EventEmbed } from "../components/eventEmbed";
import * as ToggleGroup from "@radix-ui/react-toggle-group";
import { useChatChatPostMutation } from "../API/MyMeo/auth";
import { uuid } from "short-uuid";
import { set } from "firebase/database";
import "@toast-ui/calendar/dist/toastui-calendar.min.css";
import cog from "../assets/cog.svg";
import {
  useAgreedCreateMutation,
  useCallbackCreateMutation,
  // useContactsListQuery,
  useLazyContactsListQuery,
  // usePromptCreateMutation,
  useRefreshCreateMutation,
  useSigninListQuery,
  useStreamCreateMutation,
} from "../API/ReDay/reday";
import Calendar from "@toast-ui/calendar";
import toast from "react-hot-toast";
import Lottie, { LottieRefCurrentProps, useLottie } from "lottie-react";
import doneAnimation from "../assets/animations/done.json";
import thinkerVideo from "../assets/animations/thinker.mp4";

import glogo from "../assets/g-logo.svg";
import clogo from "../assets/chrome-logo.svg";
import useExtension from "../hooks/useExtension";
import useAnalytics from "../hooks/useAnalytics";
import useTitle from "../hooks/useTitle";
const openai = new OpenAI({
  apiKey: "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",
  dangerouslyAllowBrowser: true,
});

export type GPT35Model = "gpt-3.5-turbo" | "gpt-3.5-turbo-0301";

export type GPT4Model = "gpt-4" | "gpt-4-0314" | "gpt-4-32k" | "gpt-4-32k-0314";

export type OpenAIChatRole = "user" | "assistant" | "system" | "";

export interface OpenAIChatMessage {
  content: string;
  role: OpenAIChatRole;
}
export type Model = GPT35Model | GPT4Model;
export interface OpenAIChatCompletionChunk {
  id: string;
  object: string;
  created: number;
  model: Model;
  choices: {
    delta: Partial<OpenAIChatMessage>;
    index: number;
    finish_reason: string | null;
  }[];
}

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

function getInitials(name: string) {
  const nameParts = name.split(" ");
  let initials = "";

  // Iterate over each part of the name
  for (let i = 0; i < nameParts.length; i++) {
    const part = nameParts[i];
    // Check if the part is not empty and is a letter
    if (part && /^[a-zA-Z]+$/.test(part)) {
      initials += part[0].toUpperCase();
    }
  }
  return initials.slice(0, 2); // Take the first 2 characters
}

const mapActionList = (action: string) => {
  // debugger
  switch (action) {
    case "CreateGoogleCalendarMeeting": return "Create Google Calendar Meeting";
    case "Check Contact Book": return "Check Contact Book";
    case "Check GoogleCalendar Free Time": return "Check Google Calendar Free Time";
    case "Get GoogleCalendar": return "Get Google Calendar";
    case "Put GoogleCalendar": return "Make changes in Google Calendar";
    case "Delete GoogleCalendar": return "Delete something from Google Calendar";
    case "Edit GoogleCalendar": return "Edit something in Google Calendar";
    case "Check GoogleCalendar Other User Free Time": return "Check Google Calendar Other Users";
    case "_Exception": return "Thinking...";
    default: return "Do some magic...";
  }
}


export function Prompt() {
  const [
    sendTest,
    auth,
    contactsList,
    me,
    projects,
    setActiveProject,
    activeProject,
    password,
    phoneCode,
    phoneNumber,
    inputChangeHandler,
    sendCodeHandler,
    clientStartHandler,
    loginFB,
    fb,
    user,
    gmail,
    loginGoogle,
    gcontactsList,
    events,
    setGContacts,
    eventsState
  ] = useOutletContext<any>();
  const [search, setSearch] = useState<boolean>(false);
  const [results, setResults] = useState<boolean>(false);
  const [response, setResponce] = useState<string>("");
  const [lastAnswer, setLastAnswer] = useState<string>("");
  const [asktg, setAsktg] = useState<boolean>(false);
  const [promptActive, setPromptActive] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [suggestedEvent, setSuggestedEvent] = useState<any>(null);
  const [userInput, setUserInput] = useState<string>("");
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [messages, setMessages] = useState<ChatCompletionMessageParam[]>([
    // {
    //   role: "system",
    //   content:
    //     "Imagine you want to be my personal digital assistant.\nYour aim is to help me with any kind of daily routine. \n\nAs a personal assistant you have access to:\n- own knowledge\n- my knowledge\n\t- notes\n\t- integrations\n- internet\n\n### Notes\nthis is any kind of my personal notes.\n### Integrations:\n- Browser activity\n\t- Chrome\n\t- Safari\n\t- Ark\n- Social networks\n\t- Instagram\n\t- Facebook\n\t- Linkedin\n\t- Twitter\n- Messangers\n\t- Telegram\n\t- Whatsapp\n\t- Slack\n- Taskmanagers\n\t- Monday\n\t- Jira\n\t- ClickUp\n- Email\n\t- Gmail\n- Calendar\n\t- Google Calendar\n- Fitness tracker\n\t- Apple watch\n\n\nRemember You can know everything about me that I want to share with you.\n\nBut now is is onboarding time. Your goal is to know as much about me as possible in order to set up the service and to choose Integrations of services I need. \n\nYou must ask questions to understand who I am. In what area you will be helping me. With what tasks. You can get information and take action with all of my digital social services that I gave you access. Ask questions one by one. A new question only after my answer.\n\nAfter each question if its really needs give most probable short as possible options for answering the question.Each option must be format: * _radio Text option 1 \n * _radio Text option 2 \n For multiselect use _checkbox tag: * _checkbox \n Text option 1 \n * _checkbox Text option 2 \n Communicate on Language user sent first message.When i say FINISH, you should give me a summary of my persona.\nAt the end, you have to ask one-by-one a permission to integrations I chose.\n\nyour firs message must be:\nLet's get acquainted. What's your name?",
    // },
  ]);

  const [eventsParsed, setEventsParsed] = useState<any>({});
  const [expandedEvent, setExpandedEvent] = useState<any>(null);
  const [devTools, setDevTools] = useState<boolean>(false);
  const [modelVersion, setModelVersion] = useState<string | undefined>(
    localStorage.getItem("gpt-model") || "gpt-4-turbo-2024-04-09"
  );
  const [source, setSource] = useState("reday");
  const [chatTrigger, chatState] = useChatChatPostMutation();
  // const [redayChat, redayChatState] = usePromptCreateMutation();
  const [agree, agreeState] = useAgreedCreateMutation();
  const [later, setLater] = useState<boolean>(false);
  const [refresh, refreshState] = useRefreshCreateMutation();
  const [stream, streamState] = useStreamCreateMutation();

  const [calendarOffset, setCalendarOffset] = useState<number>(0);
  const { setTitle } = useTitle();
  const [trigger, { data: readyContacts, isLoading: isContactsLoading }] =
    useLazyContactsListQuery<any>({});

  useEffect(() => {
    if (gmail) {
      trigger({
        // authorization: gmail?.token,
        // email: gmail?.user_info.email,
      }).catch(() => {});
    }
  }, [gmail]);

  useEffect(() => {
    if (readyContacts) {
      // console.log(readyContacts.contacts);
      setGContacts(readyContacts.contacts);
    }
  }, [readyContacts]);

  const navigate = useNavigate();
  const [fontSize, setFontSize] = useState("64px"); // 48
  const [lineHeight, setLineHeight] = useState("76.8px"); // 57.6
  const textRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const doneRef = useRef<LottieRefCurrentProps>(null);

  const { isExtension, extensionInstalled } = useExtension();

  const [calendarObj, setCalendarObj] = useState<any>(null);
  const [calendarView, setCalendarView] = useState<"day" | "week">("week");
  const [calendarDatelable, setCalendarDatelable] = useState<string>("");

  const [alwaysShowCalendar, setAlwaysShowCalendar] = useState<
    boolean | undefined
  >(localStorage.getItem("alwaysShowCalendar") === "true" || false);
  const [nativeShowCalendar, setNativeShowCalendar] = useState<boolean>(false);
  const [showCalendar, setShowCalendar] = useState<boolean>(false);

  useEffect(() => {
    localStorage.setItem(
      "alwaysShowCalendar",
      alwaysShowCalendar ? "true" : "false"
    );
  }, [alwaysShowCalendar]);

  useEffect(() => {
    setShowCalendar(alwaysShowCalendar || nativeShowCalendar);
  }, [alwaysShowCalendar, nativeShowCalendar]);

  const [toolState, setToolState] = useState<[string, string]>(["", ""]);

  const [suggestedEventApproved, setSuggestedEventApproved] =
    useState<boolean>(false);

  useEffect(() => {
    if (suggestedEventApproved) {
      doneRef.current?.play();
    }
  }, [suggestedEventApproved]);

  const MINUTE_MS = 60000;

  const confirmToggleValue = JSON.parse(
    localStorage.getItem("confirmToggle") || "true"
  );

  const [confirmToggle] = useState(confirmToggleValue);

  useEffect(() => {
    localStorage.setItem("gpt-model", modelVersion || "");
  }, [modelVersion]);

  useEffect(() => {
    if (!(isExtension || extensionInstalled)) {
      toast(
        (t: any) => (
          <span
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <a
              href="https://chromewebstore.google.com/detail/mymeo-extension/pkkcinijljchiogoehkbomfjcdnfmbfi"
              style={{
                color: "black",
                textDecoration: "none",
              }}
              target="_blank"
              rel="noreferrer"
            >
              {extensionInstalled
                ? "Use Chrome extension for best experience"
                : "Get Chrome extension for best experience"}
            </a>
            <button
              style={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "10px",
                marginRight: "-10px",
                cursor: "pointer",
              }}
              onClick={() => toast.dismiss(t.id)}
            >
              <Cross2Icon height={20} width={20} />
            </button>
          </span>
        ),
        {
          duration: Infinity,
          position: "top-center",
          id: "chrome-extension-toast",
          // Styling
          style: {
            maxWidth: "460px",
            padding: "4px 12px",
            border: "1px solid rgba(0, 101, 255, 1)",
            boxShadow:
              "0px 0px 0px 2px rgba(0, 101, 255, 0.25),0px 10px 30px 0px rgba(0, 0, 0, 0.15)",
          },
          className: "",

          // Custom Icon
          icon: <img src={clogo} height={16} alt="Chrome Logo" />,

          // Change colors of success/error/loading icon
          iconTheme: {
            primary: "#000",
            secondary: "#fff",
          },

          // Aria
          ariaProps: {
            role: "status",
            "aria-live": "polite",
          },
        }
      );
    }

    const interval = setInterval(() => {
      loginGoogle("auto");
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  useEffect(() => {
    // setAsktg(regex.test(response));
    if (!textRef.current) return;
    const lines =
      textRef.current.clientHeight /
      parseFloat(window.getComputedStyle(textRef.current).lineHeight);
    if (lines > 3) {
      setFontSize("48px");
      setLineHeight("57.6px");
    } else {
      setFontSize("64px");
      setLineHeight("76.8px");
    }
  }, [response]);

  useLayoutEffect(() => {
    if (!textareaRef.current) return;
    // Reset height - important to shrink on delete
    textareaRef.current.style.height = "inherit";
    // Set height
    textareaRef.current.style.height = `${Math.max(
      textareaRef.current.scrollHeight,
      100
    )}px`;

    // console.log(textareaRef.current.scrollHeight);
  }, [userInput]);

  const keyHandler = async (event: any) => {
    setUserInput(event.target.value);
  };

  useEffect(() => {
    const todayEvents = events
      .filter(
        (event: any) =>
          new Date(event.start?.dateTime).toDateString() ===
          new Date().toDateString()
      )
      .sort(
        (a: any, b: any) =>
          new Date(a.start?.dateTime).valueOf() -
          new Date(b.start?.dateTime).valueOf()
      );

    const todaysPassedEvents = todayEvents.filter(
      (event: any) => new Date(event.end?.dateTime) < new Date()
    );
    const todaysFutureEvents = todayEvents
      .filter(
        (event: any) =>
          new Date(event.start?.dateTime) > new Date() &&
          todayEvents.indexOf(event) !== -1
      )
      .sort(
        (a: any, b: any) =>
          new Date(a.start?.dateTime).valueOf() -
          new Date(b.start?.dateTime).valueOf()
      );
    const eventsInAnHour = todayEvents
      .filter((event: any) => {
        let a = new Date(event.start?.dateTime).valueOf(),
          b = new Date().valueOf();
        return a > b && a - b < 3600000;
      })
      .sort(
        (a: any, b: any) =>
          new Date(a.start?.dateTime).valueOf() -
          new Date(b.start?.dateTime).valueOf()
      );

    const meetingsInProgress = todayEvents.filter(
      (event: any) =>
        new Date(event.start?.dateTime) < new Date() &&
        new Date(event.end?.dateTime) > new Date()
    );

    const meettingsOvarlapByGroup = todayEvents.reduce(
      (acc: any, event: any) => {
        const overlap = todayEvents.filter(
          (e: any) =>
            new Date(e.start?.dateTime) < new Date(event.end?.dateTime) &&
            new Date(e.end?.dateTime) > new Date(event.start?.dateTime)
        );
        if (overlap.length > 1) {
          if (
            !acc.some((overlapGroup: any) => {
              return overlapGroup.every((e: any) => overlap.includes(e));
            })
          ) {
            acc.push(overlap);
          }
        }
        return acc;
      },
      []
    );

    // console.log("todayEvents", todayEvents);
    // console.log("todaysPassedEvents", todaysPassedEvents);
    // console.log("todaysFutureEvents", todaysFutureEvents);
    // console.log("eventsInAnHour", eventsInAnHour);
    // console.log("meetingsInProgress", meetingsInProgress);
    // console.log("meetingsOverlap", meettingsOvarlapByGroup);

    setEventsParsed({
      todayEvents,
      todaysPassedEvents,
      todaysFutureEvents,
      eventsInAnHour,
      meetingsInProgress,
      meettingsOvarlapByGroup,
    });

    let t: any;
    if (
      (t = document.body.getElementsByClassName("toastui-calendar-time")[0])
    ) {
      t.style.height = "calc(100% - 43px)";
    }
  }, [events]);

  const nearestEvent = () => {
    if (
      eventsParsed.eventsInAnHour.length &&
      featureToggle.find((f: any) => f.name === "eventsInAnHour").value
    ) {
      console.log("case 1");
      return eventsParsed.eventsInAnHour.map((event: any) => {
        const timeLeft =
          new Date(event.start?.dateTime).valueOf() - Date.now().valueOf();
        const timeLeftInMinutes = Math.floor(timeLeft / 60000);
        return (
          <div
            key={event.id}
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "20px",
            }}
          >
            <div>{timeLeftInMinutes} min before the call</div>
            <EventEmbed
              event={event}
              config={{ noline: true, atandeesLeft: true }}
            />
          </div>
        );
      });
    }

    if (
      eventsParsed.meetingsInProgress.length &&
      featureToggle.find((f: any) => f.name === "meetingsInProgress").value
    ) {
      console.log("case 2");
      return eventsParsed.meetingsInProgress.map((event: any) => {
        return (
          <div
            key={event.id}
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "20px",
            }}
          >
            <div>{event.summary} is in progress</div>
            <EventEmbed
              event={event}
              config={{ noline: true, atandeesLeft: true }}
            />
          </div>
        );
      });
    }

    if (
      eventsParsed.todaysFutureEvents.length &&
      featureToggle.find((f: any) => f.name === "todaysFutureEvents").value
    ) {
      console.log("case 3");
      return (
        <div>
          <div>
            You have{" "}
            <div
              className="events-container"
              style={{
                position: "relative",
                height: "50px",
                display: "inline-flex",
                width: eventsParsed.todaysFutureEvents.length * 50 + 110 + "px",
              }}
            >
              {eventsParsed.todaysFutureEvents.map((event: any, i: number) => {
                return (
                  <EventEmbed
                    key={event.id}
                    event={event}
                    config={{
                      noline: false,
                      atandeesLeft: false,
                      small: true,
                      number: i,
                      onClick: () => {
                        setExpandedEvent(event);
                      },
                    }}
                  />
                );
              })}
            </div>{" "}
            {eventsParsed.todaysFutureEvents.length} meetings today.
          </div>
        </div>
      );
    }

    if (
      eventsParsed.meettingsOvarlapByGroup.length &&
      featureToggle.find((f: any) => f.name === "meetingsOverlap").value
    ) {
      console.log("case 3.1");
      return eventsParsed.meettingsOvarlapByGroup.map(
        (group: any, i: number) => {
          return (
            <div
              key={i}
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "20px",
              }}
            >
              <div>
                You have {group.length} meetings overlap today.
                <div
                  className="events-container"
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    columnGap: "20px",
                  }}
                >
                  {group.map((event: any, i: number) => {
                    return (
                      <EventEmbed
                        key={event.id}
                        event={event}
                        config={{
                          noline: false,
                          atandeesLeft: true,
                          small: false,
                          onClick: () => {
                            setExpandedEvent(event);
                          },
                        }}
                      />
                    );
                  })}
                </div>{" "}
              </div>
            </div>
          );
        }
      );
    }

    if (
      eventsParsed.todaysPassedEvents.length &&
      featureToggle.find((f: any) => f.name === "todaysPassedEvents").value
    ) {
      console.log("case 4");
      const timeInTotalSpentAtEvents = eventsParsed.todaysPassedEvents.reduce(
        (acc: number, event: any) => {
          return (
            acc +
            (new Date(event.end?.dateTime).valueOf() -
              new Date(event.start?.dateTime).valueOf())
          );
        },
        0
      );

      return (
        <div>
          <div>
            You had{" "}
            <div
              className="events-container"
              style={{
                position: "relative",
                height: "50px",
                display: "inline-flex",
                width: eventsParsed.todaysPassedEvents.length * 50 + 110 + "px",
              }}
            >
              {eventsParsed.todaysPassedEvents.map((event: any, i: number) => {
                return (
                  <EventEmbed
                    key={event.id}
                    event={event}
                    config={{
                      noline: false,
                      atandeesLeft: false,
                      small: true,
                      number: i,
                      onClick: () => {
                        setExpandedEvent(event);
                      },
                    }}
                  />
                );
              })}
            </div>{" "}
            {eventsParsed.todaysPassedEvents.length} meetings today.
          </div>
          <div>
            You have spent {Math.floor(timeInTotalSpentAtEvents / 60000)}{" "}
            minutes at the meetings today.
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    setPromptActive(!!userInput);
  }, [userInput]);

  useEffect(() => {
    // console.log(activeSuggestionIndex);

    if (suggestions[activeSuggestionIndex]?.value)
      setUserInput(suggestions[activeSuggestionIndex]?.value || "");
  }, [activeSuggestionIndex]);

  useKeyPressEvent("ArrowDown", () =>
    setActiveSuggestionIndex((v) => {
      let a = v + 1;
      return a > suggestions.length - 1 ? 0 : a;
    })
  );
  useKeyPressEvent("ArrowUp", () =>
    setActiveSuggestionIndex((v) => {
      let a = (v - 1) % suggestions.length;

      return a < 0 ? suggestions.length - v : a;
    })
  );

  useKeyPressEvent("Enter", async (event) => {
    // console.log(event);
    if (event.shiftKey) {
      // event.preventDefault();
      return;
    }
    event.preventDefault();

    const regex = /\@\[([^\]]+)\]/g;

    // Extracted text
    const userInputString = userInput.replace(
      regex,
      (_: any, text: any) => text
    );

    // console.log(userInputString);

    if ((source === "reday" && !gmail) || source === "gpt") {
      setLater(true);
      const tempMessage: ChatCompletionMessageParam[] = [
        ...messages,
        {
          role: "user",
          content: userInputString,
        },
      ];
      setSearch(true);
      setResults(false);
      setLastAnswer(userInputString);
      (event as any).target.value = "";
      setUserInput("");

      const response = await openai.chat.completions.create({
        model: "gpt-3.5-turbo",
        messages: tempMessage,
        n: 1,
        stop: null,
        temperature: 0.7,
        stream: true,
        presence_penalty: 0,
        frequency_penalty: 0,
        top_p: 1,
      });
      setSearch(false);
      setResults(true);

      let content = "";
      let options = false;
      let optionList = "";

      setPromptActive(false);
      setSuggestions([]);
      setActiveSuggestionIndex(0);
      for await (const chunk of response) {
        if (chunk.choices[0].delta.content?.match(/\s*\*\s*/)) {
          options = true;
        }
        if (options) {
          optionList += chunk.choices[0].delta.content ?? "";
        } else {
          content += chunk.choices[0].delta.content ?? "";
        }
        console.log(chunk.choices);
        setResponce(content);
        const v = optionList
          .replaceAll("\n", "")
          .split("*")
          .filter((v) => !v.match(/^(| *)$/))
          .map((i) => {
            return {
              type: i.includes("_radio") ? "radio" : "checkbox",
              value: i.replaceAll(/_(radio|checkbox)\s+/g, "").trim(),
            };
          });
        console.log(v);
        setSuggestions(v);
      }

      setMessages([
        ...tempMessage,
        {
          role: "assistant",
          content: content,
        },
      ]);
    } else if (source === "reday") {
      if (!userInputString) return;

      setTitle("Looking for an answer...");
      setSearch(true);
      setResults(false);
      setSuggestions([]);
      setSuggestedEvent(null);
      setNativeShowCalendar(false);
      setLastAnswer(userInputString);
      setUserInput("");
      (event as any).target.value = "";
      let res: any;

      const requestBody: any = {
        // email: gmail.user_info.email,
        prompt: userInputString,
      };

      if (devTools) {
        requestBody.model = modelVersion;
      }

      analytics.assistancePrompt(userInputString);

      const authToken = gmail.token.split(' ')[1]

      const response = fetch("https://api.mymeo.ai/stream/", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          authorization: `Token ${authToken ?? gmail.token}`,
        },
        body: JSON.stringify(requestBody),
      })
        .then((response: any) => {
          if (!response.ok) {
            toast.error("Something went wrong, sorry.");
            setTitle("Something went wrong, sorry");
            setResponce("");
            setSuggestedEvent(null);
            setNativeShowCalendar(false);
            setSearch(false);
            setResults(true);
            setPromptActive(false);
            setSuggestions([]);
            setActiveSuggestionIndex(0);

            throw new Error("Network response was not ok");
          }
          return response;
        })
        .then(async (response) => {
          const decoder = new TextDecoder();

          // Create a StreamReader
          const reader = response.body!.getReader();

          let partialLine: any = "";
          let lines: any = [];

          let output = "";

          setResponce("");
          setPromptActive(false);
          setSuggestions([]);
          setActiveSuggestionIndex(0);

          while (true) {
            // Read chunk from the stream
            const { done, value } = await reader.read();

            if (done) {
              // If done reading, push any remaining partial line
              if (partialLine) {
                lines.push(partialLine);
              }
              break;
            }
            const chunk = decoder.decode(value, { stream: true });

            const parts = (partialLine + chunk).split("\n");

            partialLine = parts.pop();

            lines = lines.concat(parts).filter((v: any) => v);

            // Process each line
            for (const line of lines) {
              // Do something with each line (e.g., log it)
              let resp = line.split("data: ");
              if (resp.length > 1) {
                resp = resp[1];

                if (!resp.includes("done")) {
                  try {
                    resp = JSON.parse(
                      resp
                        // .replace(/'/g, '"')
                        // .replace(/\\"/g, '\\\\"')
                        .replace(/\\\\/g, "\\")
                        .replace(/\\n/g, "<br/>")
                        .replace(/None/g, "null")
                    );
                  } catch (e) {
                    // toast.error(
                    //   "Something went wrong with the response, sorry."
                    // );
                    console.warn(e, resp);
                  }

                  // debugger
                  if (resp.response) {
                    output += resp.response;
                    // console.log(output);

                    if (output) {
                      setResults(true);
                    }

                    setResponce(output);

                    if (
                      resp.response === "Something went wrong, please try again"
                    ) {
                      toast.error("Something went wrong, sorry.");
                    }
                  } else if (resp.actions) {
                    if (confirmToggle) {
                      setSuggestedEvent(resp?.actions[0] || null);
                      // setTitle("Choose an action")
                      setNativeShowCalendar(!!resp?.actions[0]);
                    } else {
                      if (!resp?.actions[0]) return;
                      try {
                        const res = await agree({
                          body: {
                            // email: gmail.user_info.email,
                            actions: resp?.actions,
                          },
                          // authorization: gmail.token,
                        });
                        setSuggestedEventApproved(true);

                        console.log("agree", res);
                      } catch (e) {
                        toast.error("Something went wrong with event, sorry.");
                      }
                    }
                  } else if (resp.tool_start || resp.tool_end) {
                    // debugger
                    setToolState([mapActionList(resp.tool_start), mapActionList(resp.tool_end)]);
                    setTitle(mapActionList(resp.tool_start))
                  } else {
                    console.log(resp);
                  }
                } else {
                  setSearch(false);
                  setToolState(["", ""]);
                }
              }
            }

            // Clear lines array
            lines = [];
          }
        })
        .catch((error) => {
          setTitle("Something went wrong, sorry");
          console.log(error);
          setResponce("");
          setSuggestedEvent(null);
          setNativeShowCalendar(false);
          setSearch(false);
          setResults(true);
          setPromptActive(false);
          setSuggestions([]);
          setActiveSuggestionIndex(0);
        });

      console.log("chat", await response);

      if (res?.error) return;

      setSearch(false);
      setResults(true);
      setPromptActive(false);
      setSuggestions([]);
      setActiveSuggestionIndex(0);
      setTitle("Here what I found for you");
    }
  });

  useEffect(() => {
    if (suggestedEvent && calendarObj) {
      if (suggestedEvent.action !== "delete") {
        calendarObj.createEvents([
          {
            id: "1",
            calendarId: "2",
            title: suggestedEvent.summary,
            category: "time",
            start: new Date(suggestedEvent.start),
            end: new Date(suggestedEvent.end),
          },
        ]);
      } else {
        calendarObj.updateEvent(suggestedEvent.id, "1", {
          calendarId: "2",
        });
      }

      setTimeout(() => {
        calendarObj.render();
      }, 100)
    } else {
      if (calendarObj) {
        setTimeout(() => {
          calendarObj.render();
        }, 100)
      }
    }
  }, [suggestedEvent, calendarObj]);

  useKeyPressEvent("q", (e) => {
    (e.ctrlKey || e.metaKey) && setDevTools((v) => !v);
  });

  useEffect(() => {
    if (suggestedEvent) {
      toast(
        (t) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                borderRadius: "20px",
                boxShadow: "0px 24px 48px -12px rgba(16, 24, 40, 0.18)",
                padding: "20px",
                background: "white",
                gap: "16px",
                maxWidth: "400px",
              }}
            >
              <div
                style={{
                  fontSize: "20px",
                  textAlign: "center",
                  // fontFamily: "Ununt"
                }}
              >
                {suggestedEvent?.summary || "No suggested event details"}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "16px",
                }}
              >
                <button
                  style={{
                    borderRadius: "12px",
                    padding: "10px 44px",
                    color: "rgb(0, 71, 225)",
                    cursor: "pointer",
                    flex: "1",
                    textAlign: "center",
                  }}
                  onClick={() => {
                    analytics.rejectedAction(JSON.stringify(suggestedEvent));
                    toast.dismiss("suggestedEvent");
                    // setSuggestedEvent(null);
                    setResults(false);
                  }}
                >
                  Reject
                </button>
                <button
                  disabled={suggestedEventApproved}
                  onClick={async () => {
                    toast.dismiss("suggestedEvent");
                    // setSuggestedEvent(null);
                    try {
                      analytics.acceptedAction(JSON.stringify(suggestedEvent));
                      const res = await agree({
                        body: {
                          actions: [suggestedEvent],
                        },
                        // authorization: gmail.token,
                      });
                      setSuggestedEventApproved(true);

                      console.log("agree", res);
                    } catch (e) {
                      toast.error("Something went wrong with event, sorry.");
                    }
                  }}
                  style={{
                    color: "white",
                    background: "rgb(0, 71, 225)",
                    borderRadius: "12px",
                    padding: "10px 44px",
                    cursor: "pointer",
                    flex: "1",
                    textAlign: "center",
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          );
        },
        {
          position: "bottom-center",
          id: "suggestedEvent",
          duration: Infinity,
          style: {
            background: "transparent",
            boxShadow: "none",
            border: "none",
            outline: "none",
          },
        }
      );
    } else {
      // toast.dismiss("suggestedEvent");
      // setNativeShowCalendar(false);
    }
  }, [suggestedEvent]);

  const featureList = [
    "eventsInAnHour",
    "meetingsInProgress",
    "meetingsOverlap",
    "todaysFutureEvents",
    "todaysPassedEvents",
  ];

  const toggles = JSON.parse(localStorage.getItem("toggles") || "[]");

  if (!toggles.length) {
    localStorage.setItem(
      "toggles",
      JSON.stringify(featureList.map((f) => ({ name: f, value: true })))
    );
  }

  const [featureToggle, setFeatureToggle] = useState<any>(
    toggles || featureList.map((f) => ({ name: f, value: true }))
  );

  const { data, isLoading } = useSigninListQuery({
    url: window.location.origin + "/",
  });

  const [code, setCode] = useState<string | null>(null);

  const [callback, callbackState] = useCallbackCreateMutation();

  const analytics = useAnalytics();

  useEffect(() => {
    if (code) {
      handleGetToket();
    } else return;
  }, [code]);

  useEffect(() => {
    // debugger;
    // console.log("contacs", readyContacts?.contacts);

    const currentURL = window.location.href;
    const urlParams = new URLSearchParams(currentURL);
    const code = urlParams.get("code");
    code?.length && setCode(code);

    const action = urlParams.get("action");
    if (action === "connectGmail") {
      gredirect();
    }

    if (!calendarObj) {
      setCalendarObj(
        new Calendar(".calendar", {
          defaultView: "week",
          theme: {

          },
          week: {
            taskView: false,
            eventView: ["time"],
            startDayOfWeek: 1,
          },
          template: {
            weekDayName(event) {
              const { date, dayName } = event;
              // console.log(event);
              return `<div style="line-height: 1em; margin-top: 6px">${date.toString()}</div><div style="line-height: 1em; font-size: .8em; margin-top: 2px">${dayName}</div>`;
            },
          },
          calendars: [
            {
              id: "1",
              name: "Work",
              backgroundColor: "#2B7FFF",
              color: "white",
            },
            {
              id: "2",
              name: "Suggested",
              backgroundColor: "#FFD700",
              color: "black",
            },
          ],
        })
      );
    }
  }, []);

  const handleGetToket = async () => {
    try {
      const res: any = await callback({
        body: {
          code: code || "",
          url: window.location.origin + "/",
        },
      });

      if ((res as any).data.user_info && (res as any).data.token) {
        // debugger
        localStorage.setItem("Reday", JSON.stringify(res.data));
        analytics.connectCalendar()
        if (window.chrome) {
          if (window.chrome.runtime) {
            window.chrome.runtime.sendMessage(
              localStorage.getItem("extension"),
              { message: "loginInCalendar", user: res.data },
              function (a: any) {
                console.log("message sent 1", a);
              }
            );
          }
        }

        navigate("/");
        loginGoogle("auto");
        // setIsLoading(false);
      }
    } catch (error) {}
  };

  const gredirect = () => {
    if (isExtension) {
      window.location.href = "https://app.mymeo.ai/?action=connectGmail";
      return;
    }

    if (!gmail && data) {
      var url = new URL((data as any).auth_link);
      url.searchParams.set("redirect_uri", window.location.origin + "/");

      console.log(url.href);
      window.location.href = url.href;
    }
  };

  if (!isLoading && data) {
    const action = new URLSearchParams(window.location.search).get("action");
    if (action === "connectGmail") {
      if (!gmail) {
        gredirect();
      } else {
        const r = JSON.parse(localStorage.getItem("Reday") || "{}");
        if (window.chrome) {
          if (window.chrome.runtime) {
            window.chrome.runtime.sendMessage(
              localStorage.getItem("extension"),
              { message: "loginInCalendar", user: r },
              function (a: any) {
                console.log("message sent 1", a);
              }
            );
          }
        }
      }
    }
  }

  const googleConnectPropose = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: "32px",
        }}
      >
        <div>
          Let me help you with managing your calendar. Please, connect one
        </div>
        <div
          style={{
            // marginTop: "2rem",
            fontSize: "24px",
            lineHeight: "34px",
          }}
        >
          I won’t do anything without your confirmation
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
            // marginTop: "2rem",
            gap: "2rem",
          }}
        >
          <button
            style={{
              backgroundColor: "rgb(0, 71, 225)",
              color: "white",
              padding: ".0rem 2rem",
              borderRadius: "12px",
              border: "none",
              cursor: "pointer",
              fontSize: ".9rem",
              fontWeight: 600,
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
              justifyContent: "center",
              height: "57px",
            }}
            onClick={() => {
              gredirect();
            }}
          >
            {" "}
            <img src={glogo} height={26} />
            Connect Google Calendar
          </button>

          {/* <div
            style={{
              fontSize: "1rem",
              color: "rgb(107, 120, 142)",
              cursor: "pointer",
            }}
            onClick={() => {
              setLater(true);
            }}
          >
            Connect later
          </div> */}
        </div>
      </div>
    );
  };

  const fullCalendar = () => {
    return (
      <div
        style={{
          display: alwaysShowCalendar || showCalendar ? "flex" : "none",
          flexDirection: "column",
          marginBottom: "20px",
          zIndex: 2,
          // visibility: showCalendar ? "visible" : "hidden",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
            gap: "10px",
            fontSize: ".875rem",
            fontWeight: 500,
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  cursor: calendarOffset === -2 ? "not-allowed" : "pointer",
                  border: "1px solid  #e5e7eb",
                  borderRadius: ".5rem",
                  padding: ".5rem",
                  height: "fit-content",
                  display: "flex",
                  opacity: calendarOffset === -2 ? 0.5 : 1,
                }}
                onClick={() => {
                  calendarOffset !== -2 && moveToNextOrPrevRange(-1);
                }}
              >
                <ArrowLeftIcon />
              </div>
              <div
                style={{
                  cursor: calendarOffset === 2 ? "not-allowed" : "pointer",
                  opacity: calendarOffset === 2 ? 0.5 : 1,
                  border: "1px solid  #e5e7eb",
                  borderRadius: ".5rem",
                  padding: ".5rem",
                  height: "fit-content",
                  display: "flex",
                }}
                onClick={() => {
                  calendarOffset !== 2 && moveToNextOrPrevRange(1);
                }}
              >
                <ArrowRightIcon />
              </div>
              {/* <div
                style={{
                  cursor: "pointer",
                  border: "1px solid  #e5e7eb",
                  borderRadius: ".5rem",
                  padding: ".5rem",
                  height: "fit-content",
                  display: "flex",
                  lineHeight: "1",
                  margin: "0 10px",
                }}
                onClick={toggleCalendarView}
              >
                {calendarView}
              </div> */}
              <div
                style={{
                  // cursor: "pointer",
                  // border: "1px solid  #e5e7eb",
                  borderRadius: ".5rem",
                  padding: ".5rem",
                  height: "fit-content",
                  display: "flex",
                  lineHeight: "1",
                }}
              >
                {calendarDatelable}
              </div>
            </div>
          </div>
          <div
            style={{
              cursor: "pointer",
              border: "1px solid  #e5e7eb",
              borderRadius: ".5rem",
              padding: ".5rem",
              height: "fit-content",
              display: "flex",
            }}
            onClick={() => setNativeShowCalendar(false)}
          >
            <Cross1Icon fontWeight={600} />
          </div>
        </div>
        <div className="calendar" style={{ height: "400px" }}></div>
      </div>
    );
  };

  function moveToNextOrPrevRange(offset: number) {
    if (!calendarObj) return;
    if (offset === -1) {
      setCalendarOffset(calendarOffset - 1);
      calendarObj.prev();
    } else if (offset === 1) {
      setCalendarOffset(calendarOffset + 1);
      calendarObj.next();
    }

    formateDateLabel();
  }

  function toggleCalendarView() {
    const view = calendarObj.getViewName();
    if (view === "day") {
      calendarObj.changeView("week");
      setCalendarView("week");
      formateDateLabel("week");
    } else {
      calendarObj.changeView("day");
      setCalendarView("day");
      formateDateLabel("day");
    }
  }

  const formateDateLabel = (type?: "week" | "day") => {
    // console.log("formateDateLabel");
    if (!calendarObj) return;

    const date = calendarObj.getDateRangeEnd().d.d;

    if ((type === "day" || calendarView === "day") && type !== "week") {
      const day = dayNames[date.getDay()];
      const month = monthNames[date.getMonth()];
      const dayOfMonth = date.getDate();
      const year = date.getFullYear();

      setCalendarDatelable(`${day} ${month} ${dayOfMonth} ${year}`);
      return;
    }

    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    setCalendarDatelable(`${month} ${year}`);
  };

  useEffect(() => {
    // console.log("calendarObj 1", calendarObj, events.length, alwaysShowCalendar || showCalendar);
    if (!calendarObj || !events || !(alwaysShowCalendar || showCalendar)) return;
    // console.log("calendarObj 2", calendarObj);

    if (!calendarObj.container) {
      calendarObj.container = document.getElementsByClassName("calendar")[0];
      
      // setTimeout(() => {
        calendarObj.render();
        calendarObj.scrollToNow("smooth");
      // }, 100)
      
      // console.log("calendarObj 2.1", calendarObj);
    } else { 
      // setTimeout(() => {
        calendarObj.render();
        // calendarObj.scrollToNow("smooth");
      // }, 100)
    }

    // console.log("calendarObj 3", calendarObj);

    const e = events.map((event: any) => {
      const selfResponce = event.attendees?.find(
        (a: any) => a.email === gmail.user_info.email
      );

      const styles: any = {};

      switch (selfResponce?.responseStatus) {
        case "needsAction":
          styles.backgroundColor = "#FFF";
          styles.color = "#2B7FFF";
          styles.borderColor = "#2B7FFF";
          break;
        case "accepted":
          styles.backgroundColor = "#2B7FFF";
          styles.borderColor = "#2B7FFF";
          break;
        case "declined":
          styles.backgroundColor = "#FFF";
          styles.color = "#2B7FFF";
          styles.borderColor = "#2B7FFF";
          styles.customStyle = { "text-decoration": "line-through" };
          break;
        case "tentative":
          styles.borderColor = "#2B7FFF";

          styles.customStyle = {
            background:
              "linear-gradient(45deg,transparent,transparent 40%,rgba(0,0,0,.2) 40%,rgba(0,0,0,.2) 50%,transparent 50%,transparent 90%,rgba(0,0,0,.2) 90%,rgba(0,0,0,.2)), #2B7FFF",
            "background-size": "20px 20px",
          };
          break;
        default:
          break;
      }
      return {
        id: event.id,
        calendarId:
          suggestedEvent &&
          suggestedEvent.action === "delete" &&
          event.id === suggestedEvent.id
            ? "2"
            : "1",
        title: event.summary,
        category: "time",
        dueDateClass: "",
        start: event.start.dateTime,
        end: event.end.dateTime,
        // backgroundColor: "#F00"
        ...styles,
      };
    });

    const s = [];

    if (suggestedEvent) {
      if (suggestedEvent.action !== "delete") {
        s.push({
          id: "1",
          calendarId: "2",
          title: suggestedEvent.summary,
          category: "time",
          start: suggestedEvent.start,
          end: suggestedEvent.end,
        });
      } else {
      }
    }

    console.log(e);
    calendarObj.clear();
    calendarObj.createEvents([...e, ...s]);
    setTimeout(() => {
      calendarObj.render();
    }, 100)
  }, [calendarObj, events, alwaysShowCalendar, showCalendar, suggestedEvent]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     calendarObj?.scrollToNow("smooth");
  //    }, 1000);

  //   formateDateLabel();
  // }, [calendarObj]);

  // useEffect(() => {
  //   console.log(toolState)
  // }, [toolState])

  const userDialogMessage = () => {
    if (response || toolState[0] || toolState[1] || search) {

      console.log('case A')
      return <div dangerouslySetInnerHTML={{ __html: response }}></div>;
    }
    if (!gmail?.user_info.email) {
      console.log('case B')
      return googleConnectPropose();
    }
    if (later) {
      console.log('case C')
      return `Hola, ${
        user.displayName || user.email
      } the Visionary! How can we shape the future today?`;
    }
      
    if (eventsParsed.todayEvents?.length) {
      // debugger
      console.log('case D')
      return nearestEvent();
    }
    if (suggestedEvent) {
      console.log('case E')
      setNativeShowCalendar(true);
    }

    console.log('case F')
    return "Let's shape the future today!";
  };

  const contactsProvider = (query: string, callback: any) => {
    if (isContactsLoading) return [{ id: "-1", display: "Loading...", login: ""}];
    if (!readyContacts) return [{ id: "-1", display: "No contacts", login: ""}];
    if (!readyContacts.contacts)
      return [{ id: "-1", display: "Empty contact list", login: ""}];
    // console.log("contactsProvider",readyContacts.contacts);
    const results = readyContacts.contacts
      .filter((c: any) => {
        return (
          c.name.toLowerCase().includes(query.toLowerCase()) ||
          c.email.toLowerCase().includes(query.toLowerCase())
        );
      })
      .map((c: any) => ({
        id: c.id,
        display: c.name,
        login: c.email,
      })).sort((a: any, b: any) => {
        return a.display.localeCompare(b.display);
       });

    callback(results);
  };

  const getAvatarImage = (suggested: any) => {
    const v = gcontactsList?.find(
      (c: any) => c?.email === suggested.login
    )?.photo_url;
    return v;
  };

  // console.log(events)

  // if ((eventsState.status !== 'fulfilled' && gmail && events.length === 0) || (gmail && events && !eventsParsed?.todayEvents)) {
  //   return (
  //     <div className="overlay">
  //       <div className="spinner center" style={{
  //         position: "absolute"
  //       }}>
  //         <div className="spinner-blade"></div>
  //         <div className="spinner-blade"></div>
  //         <div className="spinner-blade"></div>
  //         <div className="spinner-blade"></div>
  //         <div className="spinner-blade"></div>
  //         <div className="spinner-blade"></div>
  //         <div className="spinner-blade"></div>
  //         <div className="spinner-blade"></div>
  //       </div>
  //     </div>
  //   )
  // }

  return (
    <Flex
      style={{
        display: "flex",
        width: "100%",
        padding: "140px 24px",
      }}
    >
      {suggestedEventApproved && (
        <div
          style={{
            fontSize: "20px",
            textAlign: "center",
            position: "fixed",
            top: "50px",
            left: "calc(50% - 50px)",
            // right: "50%",
            zIndex: 99999,
            // fontFamily: "Ununt"
          }}
        >
          <Lottie
            lottieRef={doneRef}
            animationData={doneAnimation}
            loop={false}
            style={{
              height: "100px",
              width: "100px",
            }}
            onComplete={() => {
              doneRef.current?.stop();
              setResults(false);
              // setSuggestedEvent(null);
              setSuggestedEventApproved(false);
            }}
          />
        </div>
      )}
      {devTools && (
        <div
          style={{
            position: "absolute",
            top: "115px",
            // left: "0",
            right: "60px",

            height: "120px",
            width: "200px",
            background: "rgba(255, 255, 255, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "stretch",
            zIndex: 100,
            flexDirection: "column",
          }}
        >
          <ToggleGroup.Root
            className="ToggleGroup"
            type="single"
            defaultValue="reday"
            aria-label="Text alignment"
            onValueChange={(value) => {
              setSource(value);
            }}
          >
            <ToggleGroup.Item
              className="ToggleGroupItem"
              value="gpt"
              aria-label="Left aligned"
            >
              ChatGPT 4
            </ToggleGroup.Item>
            <ToggleGroup.Item
              className="ToggleGroupItem"
              value="api"
              aria-label="Center aligned"
              disabled
            >
              MyMeo
            </ToggleGroup.Item>
            <ToggleGroup.Item
              className="ToggleGroupItem"
              value="reday"
              aria-label="Center aligned"
            >
              Reday
            </ToggleGroup.Item>
          </ToggleGroup.Root>

          {source === "reday" && (
            <input
              style={{
                border: "1px solid #e5e7eb",
                margin: "10px 0",
              }}
              type="text"
              value={modelVersion}
              onChange={(e) => setModelVersion(e.target.value)}
              placeholder="gpt version"
            />
          )}

          {featureToggle.map((f: any) => {
            return (
              <Button
                variant={f.value ? "classic" : "outline"}
                onClick={() => {
                  setFeatureToggle((v: any) => {
                    return v.map((t: any) => {
                      if (t.name === f.name) {
                        return {
                          ...t,
                          value: !t.value,
                        };
                      }
                      return t;
                    });
                  });
                }}
              >
                {f.name}
              </Button>
            );
          })}

          <hr />

          <Button
            variant={alwaysShowCalendar ? "classic" : "outline"}
            onClick={() => {
              setAlwaysShowCalendar((v) => !v);
            }}
          >
            Always show calendar
          </Button>
        </div>
      )}

      <div
        style={{
          position: "fixed",
          right: "30px",
          top: "30px",
          cursor: "pointer",
        }}
        onClick={() => navigate("/settings")}
      >
        <img src={cog} alt="settigns" />
      </div>
      <Flex
        style={{
          display: "flex",
          alignItems: "center",
          position: "relative",
          // minHeight: "calc(100vh - 280px)",
          margin: "auto",
          flex: 1,
          maxWidth: "1024px",
          height: "fit-content",
        }}
      >
        <Flex direction={"column"} style={{ width: "100%" }}>
          {fullCalendar()}
          <Text
            size={"3"}
            style={{
              color: "rgb(107, 120, 142)",
              marginBottom: "8px",
              zIndex: 2,
            }}
          >
            {lastAnswer}
          </Text>

          {(toolState[0] || toolState[1]) && (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1,
                height: "100vh",
                width: "100vw",
              }}
            >
              <video
                style={{
                  height: "100vh",
                  width: "100vw",
                  transform: "scale(1.01)"
                }}
                src={thinkerVideo}
                autoPlay={true}
                loop
                muted
              ></video>
            </div>
          )}

          {(!search || results) && (
            <Flex
              className={promptActive ? "inactive" : ""}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                zIndex: 2,
              }}
            >
              <div
                ref={textRef}
                style={{
                  fontSize,
                  lineHeight,
                  color: "black",
                  justifyContent: "stretch",
                  display: "flex",
                  flexDirection: "column",
                  width: "inherit",
                  zIndex: 2,
                }}
              >
                {userDialogMessage()}
              </div>
            </Flex>
          )}
          {search && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "20px",
                fontSize: "48px",
                zIndex: 2,
              }}
            >
              <div className="overlay">
                <div className="spinner center">
                  <div className="spinner-blade"></div>
                  <div className="spinner-blade"></div>
                  <div className="spinner-blade"></div>
                  <div className="spinner-blade"></div>
                  <div className="spinner-blade"></div>
                  <div className="spinner-blade"></div>
                  <div className="spinner-blade"></div>
                  <div className="spinner-blade"></div>
                </div>
              </div>{" "}
              {toolState[0] || toolState[1] || "Thinking..."}
            </div>
          )}
          {suggestedEvent && (
            <>
              <EventEmbed
                style={{ marginTop: "32px" }}
                event={suggestedEvent}
                config={{
                  noline: false,
                  atandeesLeft: true,
                  desciption: true,
                  small: false,
                  reday: true,
                }}
              />
            </>
          )}
          {/* {suggestions.length ? (
            <>
              <Box
                style={{
                  marginTop: "32px",
                }}
              >
                {suggestions.map((s, i) => {
                  return (
                    <Flex
                      direction={"column"}
                      style={{
                        // marginTop: "32px",
                        rowGap: "12px",
                      }}
                    >
                      <Checkbox.Root
                        value={s.value}
                        defaultChecked={false}
                        className={
                          activeSuggestionIndex === suggestions.indexOf(s)
                            ? "active"
                            : ""
                        }
                        style={{
                          cursor: "pointer",
                          color: "rgba(0, 101, 255, 1)",
                          fontWeight: "bold",
                        }}
                        onChange={() => {
                          setUserInput(s.value);
                        }}
                      >
                        <Checkbox.Indicator />
                        <Text>{s.value}</Text>
                      </Checkbox.Root>
                    </Flex>
                  );
                })}
                <div
                  style={{
                    color: "#9ca3af",
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                >
                  Use ↑ ↓ for navigation
                </div>
              </Box>
            </>
          ) : null} */}

          {/* search: {
            JSON.stringify(search)
          }

          | suggestedEvent : {
            JSON.stringify(suggestedEvent)
          }

          | setSuggestedEventApproved : {
            JSON.stringify(suggestedEventApproved)
          }
          
          | result : {
            JSON.stringify(results)
          } */}

          {!search && !(suggestedEvent && results) && (
            <div id="prompt-area">
              <MentionsInput
                allowSuggestionsAboveCursor
                inputRef={textareaRef}
                value={userInput}
                onChange={keyHandler}
                customSuggestionsContainer={(props) => {

                  if (isContactsLoading) return <div style={{
                    padding: "5px 15px",
                      fontSize: 16,
                      backgroundColor: "transparent",
                      border: "1px solid rgba(0,0,0,0.15)",
                      maxHeight: 150,
                      overflowY: "auto",
                      borderRadius: "20px",
                      
                  }}>Loading...</div>;

                  if (!readyContacts || !readyContacts.contacts) return <div style={{
                    padding: "5px 15px",
                      fontSize: 16,
                      backgroundColor: "transparent",
                      border: "1px solid rgba(0,0,0,0.15)",
                      maxHeight: 150,
                      overflowY: "auto",
                      borderRadius: "20px",
                      
                  }}>No contacts</div>;

                  return props;
                }}
                style={{
                  control: {
                    display: "flex",
                    fontSize: "64px",
                    height: "100px",
                    // lineHeight: 1,
                    // padding: "20px 0",
                    marginTop: "32px",
                    border: "none",
                    textIndent: 0,
                    resize: "none",
                    position: "sticky",
                    bottom: 0,
                    background:
                      "linear-gradient(hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .97) 50%)",
                  },
                  ":placeholder": {
                    color: "#C2C7D0",
                  },

                  "&multiLine": {
                    control: {
                      // fontFamily: 'monospace',
                      // minHeight: 63,
                    },
                    highlighter: {
                      // padding: 9,
                      // border: '1px solid transparent',
                    },
                    input: {
                      padding: 1,
                      // border: '1px solid silver',
                    },
                  },

                  "&singleLine": {
                    // display: 'inline-block',
                    // width: 180,

                    highlighter: {
                      // padding: 1,
                      // border: '2px inset transparent',
                    },
                    input: {
                      // padding: 1,
                      // border: '2px inset',
                    },
                  },

                  suggestions: {
                    list: {
                      backgroundColor: "transparent",
                      border: "1px solid rgba(0,0,0,0.15)",
                      fontSize: 14,
                      maxHeight: 150,
                      overflowY: "auto",
                      borderRadius: "20px",
                    },
                    item: {
                      padding: "5px 15px",
                      fontSize: 16,
                      // borderBottom: '1px solid rgba(0,0,0,0.15)',
                      "&focused": {
                        backgroundColor: "rgba(230, 240, 255, 1)",
                      },
                    },
                  },
                }}
                placeholder="Ask, tell, act..."
                
              >
                <Mention
                  displayTransform={(id: any, display: any) => ` ${display}`}
                  trigger="@"
                  markup="@[__display__]"
                  data={contactsProvider}
                  appendSpaceOnAdd
                  renderSuggestion={(
                    suggestion,
                    search,
                    highlightedDisplay,
                    index,
                    focused
                  ) => {
                    return (
                     
                          <div>
                            <Avatar.Root className="AvatarRoot">
                              <Avatar.Image
                                className="AvatarImage"
                                src={getAvatarImage(suggestion)}
                                alt="suggestion.display"
                              />
                              <Avatar.Fallback
                                className="AvatarFallback"
                                delayMs={600}
                              >
                                {getInitials(suggestion.display || "")}
                              </Avatar.Fallback>
                            </Avatar.Root>{" "}
                            {suggestion.display}
                          </div>
                        
                      
                    );
                  }}
                  style={{
                    backgroundColor: "#eee",
                    borderRadius: "15px",
                    // padding: '0px 10px',
                    // fontSize: "14px",
                    // color: "red",
                  }}
                />
              </MentionsInput>
            </div>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

// initialize an empty api service that we'll inject endpoints into later as needed
export const baseSplitApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.mymeo.ai/',
    prepareHeaders: (headers, { getState }) => {
      // debugger
      // const state: any = getState() 
      // const token = state.auth?.accessToken;
      const token = localStorage.getItem('Reday') && JSON.parse(localStorage.getItem('Reday') as string)

      if (token?.token) {
        const value = token.token.split(' ')[1]
        headers.set('Authorization', `Token ${value ?? token.token}`)
      } else {
        // debugger
      }

      return headers
    }
  }),
  endpoints: () => ({}),
})
import { useNavigate } from "react-router-dom";
import {
  getAdditionalUserInfo,
  getRedirectResult,
  signInWithEmailAndPassword,
  signInWithRedirect,
  signInWithPopup
} from "firebase/auth";
import { auth } from "../firebase";
import { useEffect, useState } from "react";
import { GoogleAuthProvider } from "firebase/auth";
import { uuid } from "short-uuid";

import gLogo from "../assets/g-logo.svg";
import { Bars } from "react-loader-spinner";
import Lottie from "lottie-react";
import doneAnimation from "../assets/animations/done.json";
import useExtension from "../hooks/useExtension";
import useTitle from "../hooks/useTitle";

export function Login() {
  const [googleLoginMode, setGoogleLoginMode] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { isExtension } = useExtension();
  const provider = new GoogleAuthProvider();
  provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
  const { setTitle } = useTitle();

  // setTitle("Login")
  async function gg() {
    if (isExtension) {
      window.location.href =
        "https://app.mymeo.ai/login?action=loginWithGoogle";
    } else {
      // signInWithRedirect(auth, provider);

      signInWithPopup(auth, provider).then((result) => {
        console.log(result);
        // if (!result) {
        //   const currentURL = window.location.search;
        //   const urlParams = new URLSearchParams(currentURL);
        //   const action = urlParams.get("action");

        //   if (action === "loginWithGoogle") {
        //     gg();
        //   }
        //   setIsLoading(false);

        //   return;
        // }
        setIsLoading(false);
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const user = result?.user;
        console.log(user, getAdditionalUserInfo(result), credential);
        localStorage.setItem("SB", JSON.stringify(user));
        localStorage.setItem("uuid", uuid());

        const extension = localStorage.getItem("extension");
        if (window.chrome) {
          if (window.chrome.runtime) {
            window.chrome.runtime.sendMessage(
              extension,
              { message: "loginInWeb", user },
              function (a: any) {
                console.log("message sent", a);
              }
            );
          }
        }

        navigate("/");
      })
      .catch((error) => {
        const credential = GoogleAuthProvider.credentialFromError(error);
        setIsLoading(false);
        console.log(error, credential);
      });
    }
  }

  async function emailAndPassword() {
    if (serverErrors.email || serverErrors.password || (!email && !password))
      return;
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      console.log(user);
      localStorage.setItem("SB", JSON.stringify(user));
      localStorage.setItem("uuid", uuid());
      navigate("/");
    } catch (error) {
      setServerErrors({ email: true, password: true });
      console.log(error);
    }
  }

  useEffect(() => {

    // const currentURL = window.location.search;
    //       const urlParams = new URLSearchParams(currentURL);
    //       const action = urlParams.get("action");

    //       if (action === "loginWithGoogle") {
    //         gg();
    //       }

    setIsLoading(true);

    getRedirectResult(auth)
      .then((result) => {
        console.log(result);
        if (!result) {
          const currentURL = window.location.search;
          const urlParams = new URLSearchParams(currentURL);
          const action = urlParams.get("action");

          if (action === "loginWithGoogle") {
            gg();
          }
          setIsLoading(false);

          return;
        }
        setIsLoading(false);
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const user = result?.user;
        console.log(user, getAdditionalUserInfo(result), credential);
        localStorage.setItem("SB", JSON.stringify(user));
        localStorage.setItem("uuid", uuid());

        const extension = localStorage.getItem("extension");
        if (chrome) {
          if (chrome.runtime) {
            chrome.runtime.sendMessage(
              extension,
              { message: "loginInWeb", user },
              function (a: any) {
                console.log("message sent", a);
              }
            );
          }
        }

        navigate("/");
      })
      .catch((error) => {
        const credential = GoogleAuthProvider.credentialFromError(error);
        setIsLoading(false);
        console.log(error, credential);
      });
  }, []);

  useEffect(() => {
    setServerErrors({ email: false, password: false });
  }, [email, password]);

  const [serverErrors, setServerErrors] = useState({
    email: false,
    password: false,
  });

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {isLoading ? (
        //   <Bars
        //   height="80"
        //   width="80"
        //   color="rgb(0, 71, 225)"
        //   ariaLabel="bars-loading"
        //   wrapperStyle={{}}
        //   wrapperClass=""
        //   visible={true}
        // />
        // <Lottie
        // // lottieRef={doneRef}
        // animationData={doneAnimation}
        //   loop={true}
        //   autoPlay={true}
        // style={{
        //   height: "100px",
        //   width: "100px",
        // }}
        <div className="overlay">
          <div className="spinner center">
            <div className="spinner-blade"></div>
            <div className="spinner-blade"></div>
            <div className="spinner-blade"></div>
            <div className="spinner-blade"></div>
            <div className="spinner-blade"></div>
            <div className="spinner-blade"></div>
            <div className="spinner-blade"></div>
            <div className="spinner-blade"></div>
          </div>
        </div>
      ) : (
        // onComplete={() => {
        //   doneRef.current?.stop();
        //   setSuggestedEvent(null);
        //   setSuggestedEventApproved(false);
        // }}
        // />
        <div
          style={{
            width: "328px",
            padding: "8px",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            rowGap: "24px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "8px",
            }}
          >
            <div
              style={{
                fontFamily: "Ubuntu",
                fontSize: "32px",
                lineHeight: "38.4px",
                textAlign: "center",
              }}
            >
              Bringing the Future to Your Fingertips
            </div>
            <div
              style={{
                fontFamily: "Ubuntu",
                fontSize: "14px",
                color: "rgba(152, 161, 176, 1)",
                textAlign: "center",
                letterSpacing: "-0.02em",
              }}
            >
              Dive in and let Mymeo be your guide to the future. Because here,
              the next big thing is you.
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "12px",
            }}
          >
            {googleLoginMode && (
              <>
                <div
                  style={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "12px",
                    background: "rgba(0, 101, 255, 1)",
                    fontFamily: "Ubuntu",
                    fontSize: "14px",
                    textAlign: "center",
                    color: "white",
                    lineHeight: "16px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    columnGap: "5px",
                    cursor: "pointer",
                    fontWeight: 500,
                  }}
                  onClick={gg}
                >
                  <img src={gLogo} width={19.2} alt="google icon" />
                  Sign in with Google
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "12px",
                    color: "rgba(0, 101, 255, 1)",
                    fontFamily: "Ubuntu",
                    fontSize: "14px",
                    textAlign: "center",
                    lineHeight: "16px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    columnGap: "5px",
                    cursor: "pointer",
                    fontWeight: 500,
                  }}
                  onClick={() => setGoogleLoginMode(false)}
                >
                  Login with password
                </div>
              </>
            )}

            {!googleLoginMode && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "32px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "8px",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Ubuntu",
                        fontSize: "14px",
                        lineHeight: "19.6px",
                        letterSpacing: "-0.02em",
                        fontWeight: 600,
                        color: "black",
                      }}
                    >
                      Email
                    </div>
                    <input
                      className={
                        serverErrors.email ? "input-focus error" : "input-focus"
                      }
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      style={{
                        width: "100%",
                        height: "40px",
                        borderRadius: "12px",
                        border: "1px solid rgba(194, 199, 208, 1)",
                        padding: "0 16px",
                        boxSizing: "border-box",
                        fontFamily: "Ubuntu",
                      }}
                      type="text"
                      name=""
                      id=""
                      placeholder="Please enter your email"
                    />
                    {serverErrors.email && (
                      <div
                        style={{
                          fontFamily: "Ubuntu",
                          fontSize: "14px",
                          lineHeight: "19.6px",
                          letterSpacing: "-0.02em",
                          fontWeight: 400,
                          color: "rgba(235, 87, 87, 1)",
                        }}
                      >
                        Invalid email or password
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "8px",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Ubuntu",
                        fontSize: "14px",
                        lineHeight: "19.6px",
                        letterSpacing: "-0.02em",
                        fontWeight: 600,
                        color: "black",
                      }}
                    >
                      Password
                    </div>
                    <input
                      className={
                        serverErrors.password
                          ? "input-focus error"
                          : "input-focus"
                      }
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      style={{
                        width: "100%",
                        height: "40px",
                        borderRadius: "12px",
                        border: "1px solid rgba(194, 199, 208, 1)",
                        padding: "0 16px",
                        boxSizing: "border-box",
                      }}
                      type="password"
                      name=""
                      id=""
                      placeholder="••••••••••••"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "12px",
                  }}
                >
                  <div
                    className={
                      serverErrors.email ||
                      serverErrors.password ||
                      (!email && !password)
                        ? "disable"
                        : ""
                    }
                    style={{
                      width: "100%",
                      height: "40px",
                      borderRadius: "12px",
                      background: "rgba(0, 101, 255, 1)",
                      fontFamily: "Ubuntu",
                      fontSize: "14px",
                      textAlign: "center",
                      color: "white",
                      lineHeight: "16px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      columnGap: "5px",
                      cursor: "pointer",
                      fontWeight: 500,
                    }}
                    onClick={emailAndPassword}
                  >
                    Login
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: "40px",
                      borderRadius: "12px",
                      color: "rgba(0, 101, 255, 1)",
                      fontFamily: "Ubuntu",
                      fontSize: "14px",
                      textAlign: "center",
                      lineHeight: "16px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      columnGap: "5px",
                      cursor: "pointer",
                      fontWeight: 500,
                    }}
                    onClick={() => setGoogleLoginMode(true)}
                  >
                    Another methods to login
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

import { useState } from "react";

export default function useAuth() {
    const state = JSON.parse(localStorage.getItem("SB") || "{}")
    const [user, ] = useState(state);

    return {
        authed: !!state?.stsTokenManager?.accessToken,
        user
    }
}
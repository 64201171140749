import React, { Suspense, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Container, Theme, ThemePanel } from "@radix-ui/themes";
import { Main } from "./pages/main";
import "@radix-ui/themes/styles.css";
import {
  BrowserRouter,
  HashRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import useAuth from "./hooks/useAuth";
import { Login } from "./pages/login";
import { Contacts } from "./pages/contacts";
import { Prompt } from "./pages/prompt";
import { Note } from "./pages/note";
import { Settings } from "./pages/settings";
import { ApiProvider } from "@reduxjs/toolkit/query/react";
import { baseSplitApi } from "./API/ReDay/baseAPI";
import { Toaster } from "react-hot-toast";
import useExtension from "./hooks/useExtension";
import useAnalytics from "./hooks/useAnalytics";
function RequireAuth({ children }: any) {
  const { authed } = useAuth();
  const location = useLocation();

  return authed ? (
    children
  ) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  );
}

function Loader() {
  return <>Loading...</>;
}

function NotFound() {
  return <>Not found</>;
}

function App() {
  let isLoaded = true;
  // const navigate = useNavigate();
  const { setExtensionInstalled } = useExtension();
  const anaytics = useAnalytics();


  useEffect(() => {
    if (window.chrome) {
      if (
        window.chrome.runtime &&
        window.location.protocol !== "chrome-extension:"
      ) {
        const extension =
          localStorage.getItem("extension") ||
          "pkkcinijljchiogoehkbomfjcdnfmbfi";
        localStorage.setItem("extension", extension);
        console.log("ping", extension);
        window.chrome.runtime.sendMessage(
          extension,
          "ping",
          (response: any) => {
            if (!window.chrome.runtime.lastError) {
              // message processing code goes here
              console.log(response);
              localStorage.setItem("extensionInstalled", "true");
              setExtensionInstalled("true");
            } else {
              // error handling code goes here\
              // alert("Please install the extension");
              console.log(
                "Please install the extension",
                window.chrome.runtime.lastError
              );
              setExtensionInstalled(null);
              localStorage.removeItem("extensionInstalled");
              window.chrome.runtime.lastError = undefined;
            }
          }
        );
      }
      if (window.chrome?.storage) {
        window.chrome.storage.local.get(["Reday"], function (v: any) {
          if (v.Reday) {
            window.chrome.storage.local.set({ Reday: null }, function () {});
            // debugger
            localStorage.setItem("Reday", JSON.stringify(v.Reday));
            anaytics.connectCalendar()
            // navigate("/");
          }
        });

        window.chrome.storage.local.get(["SB"], function (v: any) {
          if (v.SB) {
            window.chrome.storage.local.set({ SB: null }, function () {});
            localStorage.setItem("SB", JSON.stringify(v.SB));
            // navigate("/");
          }
        });

        chrome.storage.onChanged.addListener((changes: any, namespace: any) => {
          console.log(changes);
          if (changes.SB?.newValue) {
            chrome.storage.local.set({ SB: null }, function () {});
            localStorage.setItem("SB", JSON.stringify(changes.SB.newValue));
            // navigate("/");
          }

          if (changes.Reday?.newValue) {
            window.chrome.storage.local.set({ Reday: null }, function () {});
            // debugger
            localStorage.setItem(
              "Reday",
              typeof changes.Reday.newValue === "string"
                ? changes.Reday.newValue
                : JSON.stringify(changes.Reday.newValue)
            );
            anaytics.connectCalendar()
            // navigate("/");
          }
        });
      }
    }
  }, []);

  return (
    <BrowserRouter>
      <ApiProvider api={baseSplitApi}>
        <Theme>
          <Toaster />
          <Suspense fallback={<Loader />}>
            {isLoaded ? (
              <Routes>
                {/* <Route path="/contacts" element={ <RequireAuth><Contacts /></RequireAuth>}  /> */}
                <Route path="/login" element={<Login />} />
                <Route path="/not-found" element={<NotFound />} />
                <Route
                  path="/"
                  element={
                    <RequireAuth>
                      <Main />
                    </RequireAuth>
                  }
                >
                  <Route path="/" element={<Prompt />} />
                  <Route path="/contacts" element={<Contacts />} />
                  {/* <Route path="/:folder/*" element={<Note />} /> */}
                  <Route path="/settings" element={<Settings />} />
                </Route>
                <Route
                  path="/index.html"
                  element={<Navigate to="/" replace />}
                />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            ) : (
              <Loader />
            )}
          </Suspense>
          {/* <ThemePanel /> */}
        </Theme>
      </ApiProvider>
    </BrowserRouter>
  );
}

export default App;

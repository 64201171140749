import { baseSplitApi as api } from "./baseAPI";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    agreedCreate: build.mutation<AgreedCreateApiResponse, AgreedCreateApiArg>({
      query: (queryArg) => ({
        url: `/agreed/`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    callbackList: build.query<CallbackListApiResponse, CallbackListApiArg>({
      query: () => ({ url: `/callback/` }),
    }),
    callbackCreate: build.mutation<
      CallbackCreateApiResponse,
      CallbackCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/callback/`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    chatList: build.query<ChatListApiResponse, ChatListApiArg>({
      query: () => ({ url: `/chat/` }),
    }),
    contactsList: build.query<ContactsListApiResponse, ContactsListApiArg>({
      query: (queryArg) => ({
        url: `/contacts/`,
        params: { force: queryArg.force },
      }),
    }),
    eventsCreate: build.mutation<EventsCreateApiResponse, EventsCreateApiArg>({
      query: (queryArg) => ({
        url: `/events/`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    loginList: build.query<LoginListApiResponse, LoginListApiArg>({
      query: (queryArg) => ({ url: `/login/`, params: { url: queryArg.url } }),
    }),
    refreshCreate: build.mutation<
      RefreshCreateApiResponse,
      RefreshCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/refresh/`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    refreshContactsList: build.query<
      RefreshContactsListApiResponse,
      RefreshContactsListApiArg
    >({
      query: () => ({ url: `/refresh_contacts/` }),
    }),
    refreshContactsCreate: build.mutation<
      RefreshContactsCreateApiResponse,
      RefreshContactsCreateApiArg
    >({
      query: () => ({ url: `/refresh_contacts/`, method: "POST" }),
    }),
    serviceCreate: build.mutation<
      ServiceCreateApiResponse,
      ServiceCreateApiArg
    >({
      query: () => ({ url: `/service/`, method: "POST" }),
    }),
    signinList: build.query<SigninListApiResponse, SigninListApiArg>({
      query: (queryArg) => ({ url: `/signin/`, params: { url: queryArg.url } }),
    }),
    streamCreate: build.mutation<StreamCreateApiResponse, StreamCreateApiArg>({
      query: (queryArg) => ({
        url: `/stream/`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    testCreate: build.mutation<TestCreateApiResponse, TestCreateApiArg>({
      query: () => ({ url: `/test/`, method: "POST" }),
    }),
    userSessionsList: build.query<
      UserSessionsListApiResponse,
      UserSessionsListApiArg
    >({
      query: () => ({ url: `/user/sessions/` }),
    }),
    workerList: build.query<WorkerListApiResponse, WorkerListApiArg>({
      query: () => ({ url: `/worker/` }),
    }),
    workerCreate: build.mutation<WorkerCreateApiResponse, WorkerCreateApiArg>({
      query: () => ({ url: `/worker/`, method: "POST" }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as coreAPIReDay };
export type AgreedCreateApiResponse = /** status 201  */ {
  actions: {
    action?: string;
    data?: object;
  }[];
};
export type AgreedCreateApiArg = {
  body: {
    actions: {
      action?: string;
      data?: object;
    }[];
  };
};
export type CallbackListApiResponse = unknown;
export type CallbackListApiArg = void;
export type CallbackCreateApiResponse = /** status 201  */ {
  code: string;
  url?: string;
};
export type CallbackCreateApiArg = {
  body: {
    code: string;
    url?: string;
  };
};
export type ChatListApiResponse = unknown;
export type ChatListApiArg = void;
export type ContactsListApiResponse = unknown;
export type ContactsListApiArg = {
  /** Force fetching contacts from gmail API */
  force?: boolean;
};
export type EventsCreateApiResponse = /** status 201  */ {
  from?: any;
  to?: any;
  eventTypes?: string[];
};
export type EventsCreateApiArg = {
  body: {
    from?: any;
    to?: any;
    eventTypes?: string[];
  };
};
export type LoginListApiResponse = unknown;
export type LoginListApiArg = {
  url?: string;
};
export type RefreshCreateApiResponse = /** status 201  */ object;
export type RefreshCreateApiArg = {
  body: object;
};
export type RefreshContactsListApiResponse = unknown;
export type RefreshContactsListApiArg = void;
export type RefreshContactsCreateApiResponse = unknown;
export type RefreshContactsCreateApiArg = void;
export type ServiceCreateApiResponse = unknown;
export type ServiceCreateApiArg = void;
export type SigninListApiResponse = unknown;
export type SigninListApiArg = {
  url?: string;
};
export type StreamCreateApiResponse = /** status 201  */ {
  prompt: string;
  model?: string;
  run_name?: string;
  stream_extra_fields?: string[];
};
export type StreamCreateApiArg = {
  body: {
    prompt: string;
    model?: string;
    run_name?: string;
    stream_extra_fields?: string[];
  };
};
export type TestCreateApiResponse = unknown;
export type TestCreateApiArg = void;
export type UserSessionsListApiResponse = unknown;
export type UserSessionsListApiArg = void;
export type WorkerListApiResponse = unknown;
export type WorkerListApiArg = void;
export type WorkerCreateApiResponse = unknown;
export type WorkerCreateApiArg = void;
export const {
  useAgreedCreateMutation,
  useCallbackListQuery,
  useLazyCallbackListQuery,
  useCallbackCreateMutation,
  useChatListQuery,
  useLazyChatListQuery,
  useContactsListQuery,
  useLazyContactsListQuery,
  useEventsCreateMutation,
  useLoginListQuery,
  useLazyLoginListQuery,
  useRefreshCreateMutation,
  useRefreshContactsListQuery,
  useLazyRefreshContactsListQuery,
  useRefreshContactsCreateMutation,
  useServiceCreateMutation,
  useSigninListQuery,
  useLazySigninListQuery,
  useStreamCreateMutation,
  useTestCreateMutation,
  useUserSessionsListQuery,
  useLazyUserSessionsListQuery,
  useWorkerListQuery,
  useLazyWorkerListQuery,
  useWorkerCreateMutation,
} = injectedRtkApi;

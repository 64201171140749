/*global chrome, FB*/
import {
  CardStackIcon,
  ChatBubbleIcon,
  PersonIcon,
  GearIcon,
} from "@radix-ui/react-icons";
import { Avatar, Box, Flex, IconButton, Separator } from "@radix-ui/themes";
import { ref, onValue, set } from "firebase/database";
import { BaseSyntheticEvent, useEffect, useState } from "react";
// import { db } from "../firebase";
import { useHotkeys } from "react-hotkeys-hook";

import { Outlet, useLocation, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

// import { gapi } from "gapi-script";
import {
  // useContactsCreateMutation,
  useContactsListQuery,
  useEventsCreateMutation,
  useLazyLoginListQuery,
  useRefreshContactsCreateMutation,
  useRefreshCreateMutation,
} from "../API/ReDay/reday";
import useAnalytics from "../hooks/useAnalytics";
// const gramjs = require("../libs/gram");

// tg.sessions.

const API_ID = 24948000;
const API_HASH = "165c74ef29634c88b295917d6c58394c";

interface IInitialState {
  phoneNumber: string;
  password: string;
  phoneCode: string;
}

const d = JSON.parse(localStorage.getItem("sb") || "{}");

const initialState: IInitialState = {
  phoneNumber: d.phone || "",
  password: d.password || "",
  phoneCode: d.code || "",
};


export function Main() {
  const [projects, setProjects] = useState<any>([]);
  const [activeProject, setActiveProject] = useState<any>(null);
  const [{ phoneNumber, password, phoneCode }, setAuthInfo] =
    useState<IInitialState>(initialState);
  const [auth, setAuth] = useState(false);
  const [contactsList, setContacts] = useState<any>([]);
  const [gcontactsList, setGContacts] = useState<any>([]);

  const [getEvents, eventsState] = useEventsCreateMutation();
  const [login, loginState] = useLazyLoginListQuery();
  // const [getContacts, contactsState] = useContactsCreateMutation();
  const [refresh, refreshState] = useRefreshCreateMutation();

  const [events, setEvents] = useState<any>([]);
  const [me, setMe] = useState<any>(null);
  const [fb, setFb] = useState<any>(null);
  const { user_info, token } = JSON.parse(localStorage.getItem("Reday") || "{}");
  const [gmail, setGmail] = useState<any>(user_info && token ? { user_info, token } : null);
  // const {data} = useContactsListQuery({ email: gmail?.email})

  const navigate = useNavigate();
  const anaytics = useAnalytics()
  // const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    console.log(gcontactsList);
  }, [gcontactsList]);

  // useHotkeys(["ctrl+k", "/", "Meta+k"], () => {
  //   console.log("hotkey");
  //   gapi.auth2.getAuthInstance().signOut();
  // });



  useEffect(() => {
    const active = projects.findIndex(
      (folder: any) => folder.id === activeProject?.id
    );
    setActiveProject(projects[active === -1 ? 0 : active]);
  }, [projects]);

  async function sendCodeHandler(): Promise<void> {
    
  }

  async function clientStartHandler(): Promise<void> {
    localStorage.setItem(
      "sb",
      JSON.stringify({
        phone: phoneNumber,
        password: password,
        code: phoneCode,
      })
    );

    // try {
    //   await client.start({
    //     phoneNumber,
    //     password: userAuthParamCallback(password),
    //     phoneCode: userAuthParamCallback(phoneCode),
    //     onError: (e: any) => {
    //       console.log(e);
    //     },
    //   });
    //   localStorage.setItem("session", JSON.stringify(client.session.save()));
    //   await client.sendMessage("me", {
    //     message: "You're successfully logged in! SecondBrain App v1.0",
    //   });
    //   setAuth(true);
    // } catch (e) {
    //   console.log(e);
    // }
  }

  function inputChangeHandler({
    target: { name, value },
  }: BaseSyntheticEvent): void {
    setAuthInfo((authInfo) => ({ ...authInfo, [name]: value }));
  }

  function userAuthParamCallback<T>(param: T): () => Promise<T> {
    return async function () {
      return await new Promise<T>((resolve) => {
        resolve(param);
      });
    };
  }

  const loginFB = () => {
    window.FB.login(
      (response: any) => {
        console.log(response);

        if (response.authResponse) {
          console.log("Welcome!  Fetching your information.... ");

          window.FB.api(
            "/me",
            { fields: "name, email, picture" },
            (res: any) => {
              setFb(res);

              FB.api(`/${res.id}/picture`, "get", {}, function (response) {
                // Insert your code here
              });
            }
          );

          window.FB.api(
            "/me/friends",
            { fields: "name, email, picture" },
            function (response: any) {
              //  document.getElementById("profile").innerHTML = "Good to see you, " + response.name + ". i see your email address is " + response.email
              console.log(response);
            }
          );
        } else {
          //  <!-- If you are not logged in, the login dialog will open for you to login asking for permission to get your public profile and email -->
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        scope: "public_profile",
      }
    );
  };

  // const loginGoogle = () => {
  const [isSignedIn, setIsSignedIn] = useState(false);

  const updateSignInStatus = (isUserSignedIn: boolean) => {
    setIsSignedIn(isUserSignedIn);
  };

  // const handleSignOutClick = () => {
  //   gapi.auth2?.getAuthInstance().signOut();
  //   setGmail(null);
  //   setEvents([]);
  //   localStorage.setItem("gmail", "");
  // };
  // }

  function getDateRange() {
    const today = new Date();
    
    // Get the date 2 weeks (14 days) before today
    const fromDate = new Date(today);
    fromDate.setDate(today.getDate() - 14);
    
    // Get the date 2 weeks (14 days) after today
    const toDate = new Date(today);
    toDate.setDate(today.getDate() + 21);
    
    // Format the dates as YYYY-MM-DDTHH:mm:ss
    const formatDate = (date: any) => {
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const seconds = String(date.getUTCSeconds()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    };
    
    return {
        from: formatDate(fromDate),
        to: formatDate(toDate)
    };
}


  const loginGoogle = async (trigger: "auto" | "button" = "button") => {
    const value = localStorage.getItem("Reday");
    console.log(trigger, value);

    // debugger

    if (trigger === "auto") {
      if (!value) {
        return;
      }
    }

    if (value) {
      const { user_info, token } = JSON.parse(value);

      setGmail({ user_info, token });

      let ev: any;
      try {
        ev = await getEvents({
          body: { ...getDateRange() },
        
          // authorization: token,
        }).unwrap();
      } catch (e: any) {
        console.log(e);
        if (e.status === 401) {
          let refreshRes: any;
          try {
            // debugger
            console.log("refreshing token");
            refreshRes = await login({
              // body: { email: user_info.email, token },
            }).unwrap();

            console.log("refreshed token", refreshRes);
          } catch (e: any) {
            // debugger
            anaytics.unintendedDisconnect("failed to refresh token A1")
            setGmail(null);
            setEvents([]);
            localStorage.setItem("Reday", "");
            return;
          }

          if (refreshRes) {
            // debugger
            try {
              ev = await getEvents({
                // body: { email: user_info.email },
                // authorization: refreshRes.token,
                body: {  },
              }).unwrap();
              // debugger
              localStorage.setItem(
                "Reday",
                JSON.stringify({ user_info, token: refreshRes.token })
              );
            } catch (error) {
              // debugger
              anaytics.unintendedDisconnect("Error after refreshing token A2")
              setGmail(null);
              setEvents([]);
              localStorage.setItem("Reday", "");
              return;
            }
            
          }
        } else if (e.status === 500 && (e.data.type === "DoesNotExist" || e.data.type === "RefreshError")) {
          // debugger
          anaytics.unintendedDisconnect("500 | DoesNotExist A3")
          setGmail(null);
          setEvents([]);
          localStorage.setItem("Reday", "");
          return;
        }
      }

      setEvents((ev as any)?.events || []);
    }

    if (trigger === "button" && gmail && value) {
      // debugger
      anaytics.disconnectCalendar()
      setGmail(null);
      setEvents([]);
      localStorage.setItem("Reday", "");
      return;
    }
  };

  function storageEventHandler(evt: any) {
    
    if (evt.key === "Reday") { 
      // debugger
    }

    console.log("oldValue: " + evt.oldValue );
    console.log("storage event called key: " + evt.key );
    console.log("newValue: " + evt.newValue);
    
    if(evt.key === "Reday"){
      loginGoogle("auto");
    }

    // if(evt.key === "SB"){
    //   console.log("SB", evt.newValue);
    //   if(evt.newValue){
    //     const { phone, password, code } = JSON.parse(evt.newValue);
    //     setAuthInfo({phoneNumber: phone, password, phoneCode: code});
    //   }
    // }
}

  useEffect(() => {
    loginGoogle("auto");
    anaytics.visitApp();
    window.addEventListener('storage', storageEventHandler, false)
  }, []);

  const goto = (a: string) => {
    navigate(`/${a}`);
  };

  const sendTest = async (text = "some cool message from SecondBrain app") => {
    // try {
    //   const contact = await client.getEntity(
    //     prompt("Enter nick of the user", "sidore_point_2") || "sidore_point_2"
    //   );
    //   await client.invoke(
    //     new tg.Api.messages.SendMessage({
    //       peer: contact,
    //       message: text,
    //     })
    //   );
    // } catch (e) {
    //   console.log(e);
    // }
  };

  return (
    <>
      <Box style={{ background: "var(--gray-a1)", height: "100vh" }}>
        <Flex
          direction={"row"}
          align={"stretch"}
          justify={"between"}
          height={"100%"}
          // style={{ overflowY: "hidden" }}
        >
          {/* <Flex direction={"row"}>
            <Box p="3">
              <Avatar
                src={
                  false
                    ? `http://graph.facebook.com/${fb.id}?type=picture`
                    : "https://images.unsplash.com/photo-1502823403499-6ccfcf4fb453?&w=256&h=256&q=70&crop=focalpoint&fp-x=0.5&fp-y=0.3&fp-z=1&fit=crop"
                }
                fallback="A"
                style={{ cursor: "pointer" }}
              />
              <Separator my="3" size="4" />
              <Flex
                gap="3"
                direction={"column"}
                justify={"center"}
                align={"center"}
              >
                <IconButton
                  variant="soft"
                  style={{ cursor: "pointer" }}
                  onClick={() => goto("")}
                >
                  <ChatBubbleIcon width="18" height="18" />
                </IconButton>
                <IconButton
                  variant="soft"
                  style={{ cursor: "pointer" }}
                  onClick={() => goto("notes")}
                >
                  <CardStackIcon width="18" height="18" />
                </IconButton>
                {/* <IconButton variant="soft" style={{ cursor: "pointer" }}>
                <LayersIcon width="18" height="18" />
              </IconButton> 

                <IconButton
                  variant="soft"
                  style={{ cursor: "pointer" }}
                  onClick={() => goto("contacts")}
                >
                  <PersonIcon width="18" height="18" />
                </IconButton>

                <IconButton
                  variant="soft"
                  style={{ cursor: "pointer" }}
                  onClick={() => goto("settings")}
                >
                  <GearIcon width="18" height="18" />
                </IconButton>
              </Flex>
            </Box>
            <Separator my="0" mx="0" size="4" orientation="vertical" />
          </Flex> */}

          <Flex
            style={{
              flex: 1,
              background: "white",
              color: "black!important",
            }}
          >
            <Outlet
              context={[
                sendTest,
                auth,
                contactsList,
                me,
                projects,
                setActiveProject,
                activeProject,
                password,
                phoneCode,
                phoneNumber,
                inputChangeHandler,
                sendCodeHandler,
                clientStartHandler,
                loginFB,
                fb,
                user,
                gmail,
                loginGoogle,
                gcontactsList,
                events,
                setGContacts,
                eventsState
              ]}
            ></Outlet>
          </Flex>
        </Flex>
      </Box>
    </>
  );
}

import AvatarGroup from "@atlaskit/avatar-group";
import "./style.css";
import { Separator } from "@radix-ui/themes";
import { useOutletContext } from "react-router-dom";

function getTimeInFormat(date: Date): string {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";

  hours = hours % 12;
  hours = hours ? hours : 12; // Handle midnight (0 hours)

  // Ensure minutes are formatted with leading zero if needed
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

  return `${hours}:${formattedMinutes}${ampm}`;
}

export const getFreeToUseAvatarImage = (number: number) =>
  `https://randomuser.me/api/portraits/${
    number % 2 === 0 ? "men" : "women"
  }/${number}.jpg`;

export const EventEmbed = (params: any) => {
  const [
    sendTest,
    auth,
    contactsList,
    me,
    projects,
    setActiveProject,
    activeProject,
    password,
    phoneCode,
    phoneNumber,
    inputChangeHandler,
    sendCodeHandler,
    clientStartHandler,
    loginFB,
    fb,
    user,
    gmail,
    loginGoogle,
    gcontactsList,
  ] = useOutletContext<any>();

  const getAvatarImage = (attendee: any) => {
    const v = gcontactsList?.find(
      (c: any) => attendee.email ? c?.email === attendee.email : c?.email === attendee
    )?.photo_url;
    return v;
  };

  const getName = (attendee: any) => {
    const v =
      gcontactsList?.find(
        (c: any) => c?.email === attendee.email || c?.email === attendee 
      )?.name || attendee.email || attendee;
    return v;
  };

  const event = params.event;
  const {
    noline,
    atandeesLeft,
    small,
    number,
    onClick,
    reday,
    desciption,
    redayAcceptCallback,
    redayRejectCallback,
    
  } = params.config;

  const start = getTimeInFormat(
    new Date(reday ? event.start : event.start.dateTime)
  );
  const end = getTimeInFormat(new Date(reday ? event.end : event.end.dateTime));

  if (small) {
    return (
      <div
        onClick={() => onClick?.(event)}
        className="event"
        style={{
          borderRadius: "12px",
          boxShadow:
            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
          display: "inline-flex",
          position: "absolute",
          left: `${number * 50}px`,
          flexDirection: "row",
          height: "55px",
          //   marginBottom: "20px",
          fontSize: "14px",
          lineHeight: 1.1,
          width: "160px",
          background: "white",
          ...params.style,
        }}
      >
        {!noline && (
          <div
            style={{
              //   height: "100%",
              background: "rgb(1 101 255)",
              width: "4px",
              borderRadius: "12px",
              margin: "10px",
            }}
          ></div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            rowGap: "6px",
            padding: "10px",
            paddingLeft: noline ? "10px" : "0px",
            flex: "1",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              textOverflow: "ellipsis",
            }}
          >
            <div
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                width: "100%",
              }}
            >
              {event.summary}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              color: "rgb(1 101 255)",
              fontWeight: 400,
              fontSize: "12px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {start} - {end}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {event.action !== 'delete' && (
        <div
        onClick={onClick}
        style={{
          borderRadius: "12px",
          boxShadow:
            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
          display: "flex",
          flexDirection: "column",
          marginBottom: "20px",
          ...params.style
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            fontSize: "18px",
            lineHeight: 1.1,
          }}
        >
          {!noline && (
            <div
              style={{
                //   height: "100%",
                background: "rgb(1 101 255)",
                width: "5px",
                borderRadius: "12px",
                margin: "10px",
              }}
            ></div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "10px",
              padding: "20px",
              paddingLeft: noline ? "20px" : "0px",
              flex: "1",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                columnGap: "10px",
              }}
            >
              <div>{event.summary}</div>
              {event.hangoutLink && (
                <div>
                  <a
                    href={event.hangoutLink}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "12px",
                      textDecoration: "none",
                      fontWeight: "bold",
                      color: "rgba(0, 101, 255, 1)",
                      alignItems: "center",
                      fontSize: "14px",
                    }}
                  >
                    <img
                      src="https://cdn.iconscout.com/icon/free/png-256/free-google-meet-7662286-6297222.png?f=webp"
                      height={14}
                      alt=""
                    />
                    Join meeting
                  </a>
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                color: "gray",
                fontWeight: 400,
                fontSize: "14px",
              }}
            >
              {start} - {end}
            </div>
            {event.attendees && atandeesLeft && (
              <div>
                <div id="avatar-list">
                  <AvatarGroup
                    maxCount={4}
                    borderColor="white"
                    size="medium"
                    appearance="stack"
                    showMoreButtonProps={{
                      className: "showMoreButton",
                      style: {
                        // background: "red!important",
                      },
                    }}
                      data={event.attendees.map((attendee: any, i: number) => {
                        // debugger
                        const value = attendee.email || attendee
                      return {
                        email: value,
                        name: getName(value),
                        src: getAvatarImage(value),
                        size: "medium",
                      };
                    })}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {desciption && event.description ? (
          <>
            <Separator size={"4"}></Separator>
            <div
              style={{
                padding: "20px",
              }}
            >
              {event.description}
            </div>
          </>
        ) : null}
      </div>
      )}
      
      {/* {reday && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            columnGap: "10px",
            marginTop: "20px",
          }}
        >
          <button
            style={{
              backgroundColor: "#1a56db",
              borderRadius: ".5rem",
              border: "1px solid #e5e7eb",
              padding: ".625rem 1.25rem",
              fontSize: "14px",
              color: "white",
              cursor: "pointer",
            }}
            onClick={() => redayAcceptCallback()}
          >
            Accept
          </button>
          <button
            style={{
              backgroundColor: "white",
              borderRadius: ".5rem",
              border: "1px solid #e5e7eb",
              padding: ".625rem 1.25rem",
              fontSize: "14px",
              color: "black",
              cursor: "pointer",
            }}
            onClick={() => redayRejectCallback()}
          >
            Decline
          </button>
        </div>
      )} */}
    </>
  );
};
